@use '../../util' as *;

.wrapper {
    .pd1__Sections {
        height: 108rem;
        width: 100%;
        max-width: 146rem;
        @include display(null, flex-start);
    }

    .pd1__section1 {
        height: clamp(67.5rem, 66.612vw + 1.421rem, 108rem);
        position: relative;
        @include display(column-reverse, center, flex-end);
        padding-top: 5rem;

        .animation--container {
            width: clamp(72rem, 78.947vw + -6.316rem, 120rem);
            height: clamp(33.8rem, 37.664vw + -3.563rem, 56.7rem);
            z-index: -1;
            margin-top: clamp(4.8rem, 6.086vw + -1.237rem, 8.5rem);
            scale: 0.9;
        }

        .content--container {
            width: 100%;
            height: auto;
            @include display(column, center, flex-start);

            h1 {
                @include text(700, clamp(5.6rem, 2.632vw + 2.989rem, 7.2rem), clamp(6.7rem, 3.454vw + 3.274rem, 8.8rem), -0.1rem, #6EADA2);
                margin-top: clamp(14.4rem, 2.632vw + 11.789rem, 16rem);
                margin-bottom: 0.8rem;
                text-align: center;
                color: #7566CC;
                transition: color 1s ease-in-out;
            }

            p {
                @include text(400, clamp(1.8rem, 0.329vw + 1.474rem, 2rem), 3.2rem, -0.05rem, #767A8D);
                text-align: center;
            }

        }
    }

    .pd1__section2 {
        height: clamp(75.8rem, 52.961vw + 23.263rem, 108rem);
        @include display(null, flex-start, space-between);
        position: relative;

        h2 {
            align-self: flex-start;
            margin-top: clamp(15rem, 18.09vw + -2.95rem, 26rem);
            width: clamp(43.7rem, 56.908vw + -12.753rem, 78.3rem);
            @include text(700, clamp(4rem, 5.263vw + -1.221rem, 7.2rem), clamp(4.8rem, 6.579vw + -1.726rem, 8.8rem), clamp(-0.1rem, calc(-0.082vw + 0.032rem), -0.05rem), #2E303A);

            strong {
                color: #4961E8;
            }
        }

        .mobile__container {
            margin-top: clamp(12rem, 15.954vw + -3.826rem, 21.7rem);
            width: clamp(43.3rem, 29.605vw + 13.932rem, 61.3rem);
            height: clamp(50.8rem, 33.059vw + 18.005rem, 70.9rem);
            scale: 1.1;

            svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1.1) !important;
                pointer-events: none;
            }
        }

        .steps__container {
            @include position(top, clamp(43.9rem, 41.118vw + 3.111rem, 68.9rem), right, clamp(43rem, 23.026vw + 20.158rem, 57rem));
            width: clamp(28.5rem, 19.572vw + 9.084rem, 40.4rem);
            height: clamp(19rem, 9.868vw + 9.211rem, 25rem);

            .step {
                @include position(top, 0, left, 0);
                width: clamp(28.5rem, 19.572vw + 9.084rem, 40.4rem);
                height: clamp(9rem, 6.25vw + 2.8rem, 12.8rem);
                border-radius: clamp(2.2rem, 1.645vw + 0.568rem, 3.2rem);
                border-bottom-right-radius: clamp(0.56rem, 0.395vw + 0.168rem, 0.8rem);
                border: 2px solid #FFF;
                background: linear-gradient(0deg, #E7E2F1 0%, #E7E2F1 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);

                .text__section {
                    width: 100%;
                    height: 100%;
                    @include display(null, null, space-between);
                    padding: clamp(1.7rem, 1.151vw + 0.558rem, 2.4rem);

                    .step--inner {
                        height: 100%;
                        width: clamp(4.5rem, 3.125vw + 1.4rem, 6.4rem);
                        border-radius: clamp(1.13rem, 0.773vw + 0.363rem, 1.6rem);
                        border-bottom-right-radius: clamp(0.28rem, 0.197vw + 0.084rem, 0.4rem);
                        background: #4961E8;
                        box-shadow: 0px 16px 24px 0px rgba(73, 97, 232, 0.40);
                        @include display(column, center, center);
                        padding: clamp(0.85rem, 0.576vw + 0.279rem, 1.2rem);

                        h3 {
                            @include text(700, clamp(2.2rem, 1.645vw + 0.568rem, 3.2rem), normal, normal, #FFF);
                        }

                        p {
                            @include text(400, clamp(0.84rem, 0.592vw + 0.253rem, 1.2rem), normal, normal, #FFF);
                            opacity: 0.75;
                        }
                    }

                    .text {
                        width: clamp(19rem, 12.829vw + 6.274rem, 26.8rem);
                        height: auto;
                        @include display(column, flex-start, center);

                        h3 {
                            @include text(700, clamp(1.48rem, 1.02vw + 0.468rem, 2.1rem), normal, normal, #2E303A);
                            margin-bottom: 0.3rem;
                        }

                        p {
                            @include text(400, clamp(0.98rem, 0.691vw + 0.295rem, 1.4rem), normal, normal, #767A8D);
                        }
                    }
                }
            }

            .step--1 {
                transform: scale(1);
            }

            .step--2 {
                transform: scale(0.9);
            }

            .step--3 {
                transform: scale(0.8);
            }

            .step.top {
                top: clamp(1.9rem, 1.316vw + 0.595rem, 2.7rem);
                background: linear-gradient(0deg, #FBFAFD 0%, #FBFAFD 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                z-index: 3;
                box-shadow: 0px 48px 64px 0px rgba(46, 48, 58, 0.12);
                transform: scale(1);
                transition: all 1s ease-in-out;
            }

            .step.middle {
                top: clamp(0.82rem, 0.461vw + 0.363rem, 1.1rem);
                background: linear-gradient(0deg, #F3F0F9 0%, #F3F0F9 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                z-index: 2;
                transform-origin: top;
                box-shadow: none;
                transform: scale(0.9);
                transition: all 1s ease-in-out;
            }

            .step.bottom {
                z-index: 1;
                transform-origin: top;
                box-shadow: none;
                transform: scale(0.8);
                animation: moveBack 1s ease-in-out;
            }

            @keyframes moveBack {

                0% {
                    background: linear-gradient(0deg, #FBFAFD 0%, #FBFAFD 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                    transform: scale(1);
                    opacity: 1;
                    z-index: 4;
                    transition: all 0.2s ease-in-out;
                }

                40% {
                    background: linear-gradient(0deg, #E7E2F1 0%, #E7E2F1 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                    transform: scale(1) translateY(100%);
                    opacity: 0;
                    z-index: 4;
                    transition: all 0s ease-in-out;
                }

                100% {
                    z-index: 1;
                    opacity: 0;
                    transform: scale(0.8);
                    transition: all 0s ease-in-out;
                }

            }

            .mobileVector {
                @include position(right, clamp(-11rem, calc(-4.934vw + -3.105rem), -8rem), bottom, clamp(0rem, calc(-2.632vw + 4.211rem), 1.6rem));
                width: clamp(8.5rem, 5.757vw + 2.789rem, 12rem);
                height: clamp(6.1rem, 4.112vw + 2.021rem, 8.6rem);
                transform: rotate(-10deg);
            }
        }
    }

    .pd1__section3 {
        @include display(column, flex-start, flex-start);
        height: clamp(83.1rem, 40.954vw + 42.474rem, 108rem);

        .heading {
            width: 100%;
            max-width: clamp(71.6rem, 55.592vw + 16.453rem, 105.4rem);
            margin-top: 3.4rem;
            margin-bottom: clamp(6.7rem, 2.138vw + 4.579rem, 8rem);

            h2 {
                @include text(700, clamp(4.8rem, 3.947vw + 0.884rem, 7.2rem), clamp(5.8rem, 4.934vw + 0.905rem, 8.8rem), clamp(-0.1rem, calc(-0.066vw + 0.005rem), -0.06rem), #2E303A);

                strong {
                    font-weight: 700;
                    color: #4961E8;
                }
            }
        }

        .image--container {
            width: 100%;
            height: clamp(45.2rem, 38.487vw + 7.021rem, 68.6rem);
            @include display(null, null, space-between);

            .left--section {
                width: clamp(32rem, 29.605vw + 2.632rem, 50rem);
                height: clamp(32rem, 29.605vw + 2.632rem, 50rem);
                @include display;
                transform: translateX(-2rem);

                .pd1-circleLeftVec {
                    width: 100%;
                    height: 100%;

                    svg {
                        user-select: none;
                    }
                }
            }

            .middle--section {
                width: clamp(22.9rem, 19.572vw + 3.484rem, 34.8rem);
                height: auto;
                @include display;
                transform: translateX(-5rem);
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 50%;
                    box-shadow: 0 78px 69px -52px rgba(145, 228, 255, 1);
                }

                .text--datsme {
                    @include position(top, 0, left, 0);
                    width: 100%;
                    height: 100%;
                    @include display;
                    z-index: 1;

                    .datsme--text {
                        @include text(600, clamp(2rem, 1.645vw + 0.368rem, 3rem), normal, null, #FFF);
                        text-shadow: 0px 3.485px 3.485px rgba(192, 226, 255, 0.50);
                        transform-origin: center;
                        width: auto;
                        height: auto;
                        opacity: 0;
                        scale: 0;
                        transition: opacity 0.3s ease-in-out, scale 0.3s ease-in-out;
                    }

                    .datsme--text.active {
                        opacity: 1;
                        scale: 1;
                    }
                }

                .text--description {
                    @include position(top, 0, left, 0);
                    width: 100%;
                    height: 100%;
                    @include display(column, center, center);
                    z-index: 2;
                    opacity: 0;
                    scale: 0;
                    transition: opacity 0.3s ease-in-out, scale 0.3s ease-in-out;

                    .timing {
                        @include text(400, clamp(1.2rem, 0.329vw + 0.874rem, 1.4rem), normal, null, #FFF);
                        margin-bottom: clamp(0.26rem, 0.23vw + 0.032rem, 0.4rem);
                        transform-origin: center;
                    }

                    .timing--border {
                        width: clamp(3rem, 1.974vw + 1.042rem, 4.2rem);
                        height: 0.1rem;
                        transform-origin: center;
                        margin-bottom: clamp(0.8rem, 0.329vw + 0.474rem, 1rem);
                        background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 3.57%, #FFF 50.78%, rgba(255, 255, 255, 0.00) 100%);
                    }

                    h2 {
                        all: unset;
                        @include text(700, clamp(1.6rem, 1.316vw + 0.295rem, 2.4rem), clamp(1.9rem, 1.48vw + 0.432rem, 2.8rem), null, #FFF);
                        max-width: clamp(17rem, 11.513vw + 5.579rem, 24rem);
                        margin-bottom: clamp(0.4rem, 0.658vw + -0.253rem, 0.8rem);
                        transform-origin: center;
                        text-align: center;
                    }

                    p {
                        @include text(400, clamp(1.2rem, 0.329vw + 0.874rem, 1.4rem), normal, null, #FFF);
                        max-width: clamp(17rem, 11.513vw + 5.579rem, 24rem);
                        margin-bottom: clamp(1.4rem, 3.783vw + -2.353rem, 3.7rem);
                        text-align: center;
                        transform-origin: center;
                    }

                    .songIcon {
                        all: unset;
                        width: clamp(5rem, 6.743vw + -1.689rem, 9.1rem);
                        height: clamp(4rem, 5.428vw + -1.384rem, 7.3rem);
                        transform-origin: center;
                        user-select: none;
                        pointer-events: none;

                        svg {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .text--description.active {
                    opacity: 1;
                    scale: 1;
                }
            }

            .right--section {
                height: 100%;
                width: auto;
                @include display(column, flex-end, space-between);

                .section {
                    width: auto;
                    height: auto;
                    @include display;
                    position: relative;

                    &--container {
                        width: clamp(18.2rem, 15.625vw + 2.7rem, 27.7rem);
                        height: clamp(5.4rem, 4.77vw + 0.668rem, 8.3rem);
                        padding: clamp(0.9rem, 0.658vw + 0.247rem, 1.3rem);
                        @include display(null, flex-start, space-between);
                        border-radius: clamp(1.4rem, 0.987vw + 0.421rem, 2rem);
                        background: radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                        box-shadow: 0px 6.9px 34.6px 0px rgba(66, 83, 146, 0.12);
                        cursor: pointer;

                        .content {
                            width: clamp(14.1rem, 12.007vw + 2.189rem, 21.4rem);
                            height: 100%;
                            @include display(null, null, space-between);
                            user-select: none;
                            pointer-events: none;

                            img {
                                width: clamp(3.6rem, 3.125vw + 0.5rem, 5.5rem);
                                height: clamp(3.6rem, 3.125vw + 0.5rem, 5.5rem);
                                margin-right: 1.4rem;
                            }

                            .inner--heading {
                                width: auto;
                                height: auto;
                                @include display(column, flex-start, flex-start);

                                span {
                                    @include display(null, center, flex-start);
                                    width: auto;
                                    height: auto;
                                    @include text(500, clamp(0.57rem, 0.477vw + 0.097rem, 0.86rem), normal, normal, #767A8D);

                                    img {
                                        margin-right: clamp(0.22rem, 0.214vw + 0.008rem, 0.35rem);
                                        width: clamp(0.9rem, 0.658vw + 0.247rem, 1.3rem);
                                        height: clamp(0.9rem, 0.658vw + 0.247rem, 1.3rem);
                                    }
                                }

                                h3 {
                                    @include text(600, clamp(0.8rem, 0.658vw + 0.147rem, 1.2rem), normal, normal, #2E303A);
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 100%;
                                }
                            }
                        }

                        .play--button {
                            @include display;
                            width: auto;
                            height: 100%;
                            cursor: pointer;

                            img {
                                width: 2rem;
                                height: 2rem;
                                box-shadow: 0px 6.761px 13.522px 0px rgba(131, 131, 131, 0.32);
                                border-radius: 50%;
                                display: none;
                            }

                            .play.active {
                                display: block;
                            }

                            .pause.active {
                                display: block;
                            }
                        }
                    }

                    .hoverLine {
                        @include position(top, 50%, left, 0);
                        transform: translateX(-75%);
                        z-index: -1;
                        width: clamp(28.3rem, 24.178vw + 4.316rem, 43rem);
                        height: clamp(10.6rem, 9.046vw + 1.626rem, 16.1rem);
                    }

                    audio {
                        @include position(top, 0, left, 0);
                        width: auto;
                        height: auto;
                    }
                }

                .section4 {
                    .hoverLine {
                        top: -130%;
                    }
                }

                .section5,
                .section6,
                .section7 {
                    .hoverLine {
                        top: -140%;
                    }
                }

                .section1,
                .section7 {
                    margin-right: clamp(8.2rem, 7.072vw + 1.184rem, 12.5rem);
                }

                .section2,
                .section6 {
                    margin-right: clamp(5.4rem, 4.77vw + 0.668rem, 8.3rem);
                }

                .section3,
                .section5 {
                    margin-right: clamp(2.7rem, 2.303vw + 0.416rem, 4.1rem);
                }

                .section1 .section--container.active,
                .section1 .section--container:hover {
                    background: linear-gradient(251deg, #E5BFFD -19.28%, rgba(229, 191, 253, 0.00) 80.1%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                }

                .section2 .section--container.active,
                .section2 .section--container:hover {
                    background: linear-gradient(256deg, #C8E6F6 -20.36%, rgba(200, 230, 246, 0.00) 64.18%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                }

                .section3 .section--container.active,
                .section3 .section--container:hover {
                    background: linear-gradient(258deg, #FFE7E7 -21.05%, rgba(255, 231, 231, 0.00) 55.32%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                }

                .section4 .section--container.active,
                .section4 .section--container:hover {
                    background: linear-gradient(267deg, #D3EEEE -23%, rgba(211, 238, 238, 0.00) 56.54%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                }

                .section5 .section--container.active,
                .section5 .section--container:hover {
                    background: linear-gradient(258deg, #FFE7E7 -21.05%, rgba(255, 231, 231, 0.00) 55.32%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                }

                .section6 .section--container.active,
                .section6 .section--container:hover {
                    background: linear-gradient(256deg, #C8E6F6 -20.36%, rgba(200, 230, 246, 0.00) 64.18%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                }

                .section7 .section--container.active,
                .section7 .section--container:hover {
                    background: linear-gradient(251deg, #FFE7E7 -19.28%, rgba(255, 231, 231, 0.00) 80.1%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                }
            }
        }
    }

    .pd1__section4 {
        height: clamp(69.9rem, 37.664vw + 32.537rem, 92.8rem);

        .section4__container {
            width: 100%;
            height: clamp(38.3rem, 29.276vw + 9.258rem, 56.1rem);
            margin-top: clamp(16.9rem, 3.783vw + 13.147rem, 19.2rem);
            border-radius: 4.8rem;
            background: linear-gradient(315deg, #0F31E6 0%, #536DF4 100%);
            @include display(null, center, space-between);
            padding: clamp(5rem, 2.632vw + 2.389rem, 6.6rem) clamp(4.5rem, 8.553vw + -3.984rem, 9.7rem);
            box-shadow: 0 12rem 7rem -5.5rem rgba(83, 105, 244, 0.6);

            .content {
                width: 100%;
                height: 100%;
                max-width: clamp(33.4rem, 42.434vw + -8.695rem, 59.2rem);
                @include display(column, flex-start, flex-start);

                h2 {
                    @include text(700, clamp(4rem, 5.263vw + -1.221rem, 7.2rem), normal, clamp(-0.1rem, calc(-0.082vw + 0.032rem), -0.05rem), #FFF);
                    margin-bottom: clamp(1.6rem, 2.632vw + -1.011rem, 3.2rem);
                }

                p {
                    @include text(500, clamp(1.6rem, 1.316vw + 0.295rem, 2.4rem), clamp(2.4rem, 1.974vw + 0.442rem, 3.6rem), null, #FFF);
                    margin-bottom: 1.6rem;
                }

                span {
                    @include text(400, clamp(1.4rem, 0.329vw + 1.074rem, 1.6rem), clamp(2.1rem, 1.151vw + 0.958rem, 2.8rem), -0.05rem, #FFF);
                    opacity: 0.69;
                }
            }

            .image-container {
                width: clamp(35.5rem, 19.572vw + 16.084rem, 47.4rem);
                height: 100%;
                position: relative;
                margin-bottom: clamp(6.2rem, 7.895vw + -1.632rem, 11rem);

                button {
                    width: 4.8rem;
                    height: 4.8rem;
                    border-radius: 50%;
                    @include position(top, clamp(9.5rem, 13.98vw + -4.368rem, 18rem), left, 50%);
                    transform: translate(-50%, -50%);
                    z-index: 100;
                    overflow: hidden;
                    @include display;
                    box-shadow: 0px 16px 24px 0px rgba(102, 114, 156, 0.24);
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .next {
                    left: 100%;
                }

                .previous {
                    left: 0;
                }

                .video--container {
                    @include position(bottom, 0, left, 0);
                    width: clamp(35.5rem, 19.572vw + 16.084rem, 47.4rem);
                    height: clamp(37.5rem, 20.395vw + 17.268rem, 49.9rem);
                    border-radius: clamp(1.4rem, 0.987vw + 0.421rem, 2rem);
                    box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.16);
                    z-index: 2;
                    @include display();

                    .play--logo {
                        width: clamp(7.5rem, 4.112vw + 3.421rem, 10rem);
                        height: clamp(7.5rem, 4.112vw + 3.421rem, 10rem);
                        border-radius: 50%;
                        filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.16));
                        z-index: 9;
                        cursor: pointer;
                        @include position(top, 50%, left, 50%);
                        transform: translate(-50%, -50%);

                        &:hover {
                            filter: none;
                        }
                    }

                    .play--logo.hide {
                        opacity: 0;
                        z-index: 5;
                        transition: opacity 0.5s ease-in-out, z-index 0.8s ease-in-out;
                    }

                    .thumbnail {
                        @include position(top, 50%, left, 50%);
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                        z-index: 8;
                    }

                    .thumbnail.hide {
                        opacity: 0;
                        z-index: 6;
                        transition: opacity 1.2s ease-in-out, z-index 1.2s ease-in-out;
                    }

                    .youtube-video {
                        @include position(top, 50%, left, 50%);
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                        z-index: 7;
                    }

                }

                .bottom {
                    opacity: 1;
                    z-index: 1;
                    transform: rotate(7deg);
                    box-shadow: none;
                    overflow: hidden;

                    .overlay {
                        @include position(top, 0, left, 0);
                        width: 100%;
                        height: 100%;
                        background: #8295FD;
                        z-index: 10;
                        opacity: 1;
                    }
                }

                .middle {
                    opacity: 1;
                    z-index: 2;
                    transform: rotate(0deg);
                    box-shadow: none;
                    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
                    overflow: hidden;

                    .overlay {
                        @include position(top, 0, left, 0);
                        width: 100%;
                        height: 100%;
                        background: #8295FD;
                        z-index: 4;
                        opacity: 0;
                        transition: opacity 0.5s ease-in-out, z-index 0s ease-in-out 0.4s;
                    }
                }

                .top {
                    opacity: 0;
                    z-index: 0;
                    transform: rotate(7deg);
                    box-shadow: none;
                    overflow: hidden;

                    .overlay {
                        @include position(top, 0, left, 0);
                        width: 100%;
                        height: 100%;
                        background: #8295FD;
                        z-index: 10;
                        opacity: 1;
                    }
                }

            }
        }
    }

    .pd1__section5 {
        position: relative;
        height: 300vh;
        max-width: 100%;

        .section5__mobileView,
        .mobileBackground {
            display: none;
        }

        .background {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            transform: translateX(-6.4rem);
            transition: background 0.3s ease-in-out;
        }

        .fixedLayout {
            display: flex;
            position: sticky;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            z-index: 10;
            @include display;
            max-width: 146rem;
            overflow: hidden;

            &__wrapper {
                width: 100%;
                height: auto;
                @include display(null, flex-start, space-between);
            }

            .image--container {
                width: clamp(38.3rem, 13.98vw + 24.432rem, 46.8rem);
                height: clamp(45.5rem, 16.612vw + 29.021rem, 55.6rem);
                position: relative;

                .sec5MainImg {
                    z-index: 2;
                    @include position(bottom, 0, left, 0);
                    opacity: 0;
                    transform: translateY(100vh);
                    transition: transform 0.4s ease-in-out 0.2s, opacity 0.3s ease-in-out 0.1s;
                    border-radius: 3.2rem;
                    overflow: hidden;
                    background: #FFF;
                    box-shadow: 0px 60px 39px -35px rgba(40, 63, 59, .16);

                    svg {
                        user-select: none;
                    }
                }

                .sec5MainImg.show {
                    opacity: 1;
                    transform: translateY(0);
                }

                .sec5HoverContent {
                    border-bottom-right-radius: 2.4rem;
                    border-top-right-radius: 2.4rem;
                    background: rgba(255, 255, 255, 0.16);
                    @include position(bottom, clamp(4.6rem, 2.961vw + 1.663rem, 6.4rem), right, 0);
                    transform: translateX(-250%);
                    padding: clamp(3rem, 2.961vw + 0.063rem, 4.8rem) clamp(2.8rem, 3.289vw + -0.463rem, 4.8rem) clamp(3rem, 2.961vw + 0.063rem, 4.8rem) 3rem;
                    width: clamp(23.9rem, 12.993vw + 11.011rem, 31.8rem);
                    height: clamp(14rem, 8.553vw + 5.516rem, 19.2rem);
                    opacity: 0;
                    z-index: 1;
                    transition: opacity 0.3s ease-in-out, transform 0.85s ease-in;
                    @include display(null, center, flex-start);

                    p {
                        @include text(400, clamp(1.6rem, 0.658vw + 0.947rem, 2rem), clamp(2.4rem, 1.316vw + 1.095rem, 3.2rem), -0.05rem, #FFF);
                        opacity: 0.7;
                    }
                }

                .sec5HoverContent.show {
                    opacity: 1;
                    transform: translateX(98%);
                }
            }

            .mainImg1 {
                width: clamp(38.3rem, 13.98vw + 24.432rem, 46.8rem);
                height: clamp(45.5rem, 16.612vw + 29.021rem, 55.6rem);
            }

            .mainImg2 {
                width: clamp(38.3rem, 13.98vw + 24.432rem, 46.8rem);
                height: clamp(42rem, 13.158vw + 28.947rem, 50rem);

                svg {
                    position: absolute;
                    top: 25px;
                    left: -10px;
                }
            }

            .mainImg3 {
                width: clamp(38.3rem, 13.98vw + 24.432rem, 46.8rem);
                height: clamp(42rem, 13.158vw + 28.947rem, 50rem);

                svg {
                    position: absolute;
                    left: -10px;
                    top: 0;
                }
            }

            .content--container {
                margin-top: clamp(-6rem, calc(9.868vw + -15.789rem), 0rem);
                width: clamp(43.5rem, 56.743vw + -12.789rem, 78rem);
                height: auto;
                @include display(column, flex-start, space-between);

                .top--section {
                    @include display(null, flex-end, flex-start);
                    width: auto;
                    height: 8.7rem;
                    margin-bottom: 1.2rem;
                    position: relative;

                    span {
                        @include text(300, 3.2rem, normal, -0.1rem, #FFF);
                        margin-right: 3.2rem;
                    }

                    .sec5IconImg {
                        @include position(right, -8.7rem, bottom, 0);
                        width: 8.7rem;
                        height: 8.7rem;
                        will-change: transform;
                        transform-origin: center;
                        transform: scale(0);
                        opacity: 0;
                        transition: transform 0.4s ease-in-out 0.2s, opacity 0.3s ease-in-out 0.1s;
                    }

                    .show {
                        opacity: 1;
                        transform: scale(1);
                    }
                }

                h2 {
                    width: 100%;
                    height: clamp(9rem, 9.868vw + -0.789rem, 15rem);
                    @include text(200, clamp(6.8rem, 8.553vw + -1.684rem, 12rem), normal, -0.14rem, #FFF);
                    position: relative;

                    .sec5HeadingContent {
                        @include position(left, 0, top, 0);
                        font-weight: 700;
                        opacity: 0;
                        transform: translateX(100vh);
                        transition: transform 0.4s ease-in-out 0.2s, opacity 0.3s ease-in-out 0.1s;
                    }

                    .show {
                        opacity: 1;
                        transform: translateX(0);
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }

    .pd1__section6 {
        @include display(null, flex-start, space-between);
        height: clamp(66.7rem, 67.928vw + -0.684rem, 108rem);
        margin-bottom: clamp(4rem, 6.58vw + -2.53rem, 8rem);

        .section--wrapper {
            @include display(null, flex-end, space-between);
            width: 100%;
            height: auto;
            margin-top: clamp(12.3rem, 22.697vw + -10.216rem, 26.1rem);

            .image--container {
                width: clamp(36rem, 56.25vw + -19.8rem, 70.2rem);
                height: clamp(42.1rem, 65.954vw + -23.326rem, 82.2rem);

                svg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    transform: scale(1) !important;
                    user-select: none;
                    pointer-events: none;
                }
            }

            .content--container {
                width: auto;
                height: auto;
                max-width: clamp(42.4rem, 33.388vw + 9.279rem, 62.7rem);
                @include display(column, flex-start, space-between);
                align-self: flex-start;

                img {
                    width: clamp(13.9rem, 10.855vw + 3.132rem, 20.5rem);
                    height: clamp(13.9rem, 8.059vw + 5.905rem, 18.8rem);
                    margin-bottom: clamp(1.6rem, 2.632vw + -1.011rem, 3.2rem);
                }

                h2 {
                    @include text(700, clamp(4rem, 3.947vw + 0.084rem, 6.4rem), normal, -0.1rem, #2E303A);
                    margin-bottom: clamp(0.8rem, 3.947vw + -3.116rem, 3.2rem);

                    strong {
                        color: #977EC8;
                    }
                }

                p {
                    @include text(600, clamp(1.6rem, 0.822vw + 0.784rem, 2.1rem), clamp(2.4rem, 1.316vw + 1.095rem, 3.2rem), normal, #12141D);
                    margin-bottom: 1.6rem;
                }

                span {
                    @include text(400, clamp(1.6rem, 0.658vw + 0.947rem, 2rem), clamp(2.4rem, 1.316vw + 1.095rem, 3.2rem), -0.05rem, #767A8D);
                    opacity: 0.7;
                }
            }
        }
    }

    .pd1__section7 {
        @include display(column, flex-start, center);
        height: 108rem;

        @media (max-width: 1600px) {
            height: auto;
            padding: 7rem 0;
        }

        .sec7__content--container {
            width: 113rem;
            height: 29rem;
            @include display(column, flex-start, space-between);
            margin-bottom: 4.4rem;

            @media (max-width: 1600px) {
                width: clamp(45.3rem, 89.967vw + -43.947rem, 100rem);
                height: auto;
            }

            h2 {
                @include text(700, clamp(4rem, 8.224vw + -4.158rem, 9rem), clamp(4.8rem, 9.375vw + -4.5rem, 10.5rem), -0.2rem, #2E303A);
                margin-bottom: clamp(1.6rem, 1.316vw + 0.295rem, 2.4rem);

                span {
                    color: #4861E6;
                }
            }

            p {
                @include text(400, clamp(1.4rem, 0.657vw + 0.749rem, 1.8rem), clamp(2.1rem, 0.987vw + 1.121rem, 2.7rem), -0.05rem, #767A8D);
                max-width: 90rem;
            }
        }

        .sec7__image--container {
            width: 123.5rem;
            height: 62.8rem;
            padding: 3.1rem 3.6rem;
            @include display;
            flex-wrap: wrap;
            border-radius: 3.2rem;
            background: radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
            box-shadow: 0 4.568px 22.841px 0 rgba(66, 83, 146, 0.12);
            gap: 2.4rem;
            align-self: flex-end;
            position: relative;

            .profiles {
                width: auto;
                height: 27rem;
                border-radius: 1.6rem;
            }

            .hover__container {
                @include position(top, 50%, left, 0);
                transform: translate(-22rem, -50%);
                width: 37.1rem;
                height: 18.2rem;

                .text--section {
                    box-shadow: 0 48px 64px 0 rgba(46, 48, 58, 0.12);
                    border-radius: 3.2rem 3.2rem 0.8rem 3.2rem;
                    @include position(top, 0, left, 0);
                    width: 100%;
                    height: 100%;
                    @include display(column, flex-start, space-between);
                    padding: 3.2rem;
                    z-index: 0;
                    background: linear-gradient(0deg, #FBFAFD 0%, #FBFAFD 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);

                    h2 {
                        @include text(700, clamp(1.6rem, 0.822vw + 0.784rem, 2.1rem), normal, null, #2E303A);
                        margin-bottom: 1.2rem;
                    }

                    p {
                        @include text(400, clamp(1.2rem, 0.329vw + 0.874rem, 1.4rem), normal, null, #767A8D);
                        opacity: 0.75;
                    }
                }

                .hoverVector {
                    @include position(top, -100%, left, 0);
                    width: 21rem;
                    height: 15.6rem;
                }
            }

            @media (max-width: 1600px) {
                width: 100%;
                height: auto;
                padding: clamp(2.4rem, 5.921vw + -3.474rem, 6rem) clamp(2.4rem, 1.974vw + 0.442rem, 3.6rem);
                border-radius: clamp(2.4rem, 1.316vw + 1.095rem, 3.2rem);

                .profiles {
                    height: clamp(18rem, 23.026vw + -4.842rem, 32rem);
                    border-radius: clamp(1.2rem, 0.658vw + 0.547rem, 1.6rem);
                }

                .hover__container {
                    height: clamp(15rem, 5.263vw + 9.779rem, 18.2rem);
                    width: clamp(33rem, 6.743vw + 26.311rem, 37.1rem);
                    left: unset;
                    @include position(top, clamp(-30rem, calc(-14.803vw + -6.316rem), -21rem), right, 0);
                    transform: translate(0, 0);

                    .text--section {
                        padding: clamp(2.4rem, 1.316vw + 1.095rem, 3.2rem);
                    }

                    .hoverVector {
                        @include position(top, 50%, left, clamp(-12rem, calc(-8.224vw + 1.158rem), -7rem));
                        rotate: 90deg;
                        z-index: -1;
                        height: auto;
                        width: clamp(10rem, 11.513vw + -1.421rem, 17rem);
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .wrapper {
        .pd1__section7 {
            .sec7__content--container {
                width: clamp(38.1rem, 32.143vw + 13.414rem, 45.3rem);
                margin-bottom: clamp(4.4rem, calc(-16.964vw + 21.229rem), 8.2rem);

                h2 {
                    font-size: clamp(3.6rem, 1.786vw + 2.229rem, 4rem);
                    line-height: clamp(4.3rem, 2.232vw + 2.586rem, 4.8rem);
                    margin-bottom: clamp(0.8rem, 3.571vw + -1.943rem, 1.6rem);
                }
            }

            .sec7__image--container {
                padding: clamp(1.7rem, 1.149vw + 0.561rem, 2.4rem);
                gap: clamp(1.37rem, 4.598vw + -2.161rem, 2.4rem);
                border-radius: clamp(1.8rem, 2.679vw + -0.257rem, 2.4rem);

                .profiles {
                    height: clamp(15.4rem, 11.607vw + 6.486rem, 18rem);
                    border-radius: clamp(1rem, 0.893vw + 0.314rem, 1.2rem);
                }

                .hover__container {
                    height: clamp(14.1rem, 4.018vw + 11.014rem, 15rem);
                    width: clamp(30rem, 13.839vw + 19.271rem, 33rem);
                    top: clamp(-21rem, calc(-17.857vw + -3.286rem), -17rem);

                    .text--section {
                        h2 {
                            margin-bottom: 0.8rem;
                        }
                    }

                    .hoverVector {
                        left: clamp(-7rem, calc(-4.464vw + -2.571rem), -6rem);
                        width: clamp(8.5rem, 6.696vw + 3.357rem, 10rem);
                        top: 60%;
                    }
                }
            }
        }

        .pd1__section1 {
            height: 110rem;

            .animation--container {
                width: 70.2rem;
                height: 62rem;
                margin-top: 7.2rem;
                scale: 1;
            }

            .content--container {
                width: 100%;
                max-width: 51rem;
                height: auto;
                @include display(column, center, flex-start);

                h1 {
                    margin-top: 13rem;
                    margin-bottom: 1.6rem;
                }

                p {
                    max-width: 46rem;
                    @include text(400, 2rem, 3.2rem, -0.05rem, #767A8D);
                    text-align: center;
                }

            }
        }

        .pd1__section2 {
            height: 82.3rem;
            position: relative;

            h2 {
                margin-top: 13.3rem;
                width: clamp(38.5rem, 23.214vw + 20.671rem, 43.7rem);
                height: auto;
                font-size: clamp(3.6rem, 1.786vw + 2.229rem, 4rem);
                line-height: clamp(4.3rem, 2.232vw + 2.586rem, 4.8rem);
                z-index: 10;
            }

            .mobile__container {
                @include position(top, 0, right, 0);
                margin-top: 22.7rem;
                width: 46.2rem;
                height: clamp(47.3rem, 31.25vw + 23.3rem, 54.3rem);
                overflow: hidden;
            }

            .steps__container {
                top: 54rem;
                right: clamp(42rem, 35.714vw + 14.571rem, 50rem);
            }
        }

        .pd1__section3 {
            @include display(column, flex-start, flex-start);
            height: clamp(86.5rem, 28.571vw + 64.557rem, 92.9rem);

            .heading {
                max-width: clamp(34rem, 58.036vw + -10.571rem, 47rem);
                margin-top: 8rem;
                margin-bottom: 9.5rem;
                margin-right: 5.7rem;
                height: auto;
                align-self: flex-end;

                h2 {
                    font-size: clamp(3.6rem, 5.357vw + -0.514rem, 4.8rem);
                    line-height: clamp(4.3rem, 6.696vw + -0.843rem, 5.8rem);
                }
            }

            .image--container {
                width: 100%;
                height: 45.2rem;
                @include display(null, null, space-between);
                position: relative;

                .left--section {
                    width: 25.8rem;
                    height: 25.8rem;
                    @include display;
                    transform: translateX(0);
                    @include position(top, clamp(-34rem, calc(-13.393vw + -20.714rem), -31rem), left, 0);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .middle--section {
                    width: 41.1rem;
                    height: auto;
                    @include display;
                    transform: translateX(0);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        border-radius: 50%;
                        box-shadow: 0 78px 69px -52px rgba(145, 228, 255, 1);
                    }

                    .text--datsme {
                        .datsme--text {
                            font-size: 3.8rem;
                            text-shadow: 0px 4.12px 4.12px rgba(192, 226, 255, 0.50);
                        }
                    }

                    .text--description {

                        .timing {
                            font-size: 1.6rem;
                            margin-bottom: 0.4rem;
                        }

                        .timing--border {
                            width: 5rem;
                        }

                        h2 {
                            font-size: 2.8rem;
                            line-height: 3.4rem;
                            max-width: 28rem;
                            margin-bottom: 0.8rem;
                        }

                        p {
                            font-size: 1.6rem;
                            max-width: 24rem;
                            margin-bottom: 4.7rem;
                        }

                        .songIcon {
                            width: 10.8rem;
                            height: 8.6rem;

                            svg {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }

                .right--section {
                    height: 100%;
                    width: auto;
                    @include display(column, flex-end, space-between);

                    .section {
                        width: auto;
                        height: auto;
                        @include display;
                        position: relative;

                        &--container {
                            width: clamp(18.2rem, 15.625vw + 2.7rem, 27.7rem);
                            height: clamp(5.4rem, 4.77vw + 0.668rem, 8.3rem);
                            padding: clamp(0.9rem, 0.658vw + 0.247rem, 1.3rem);
                            @include display(null, flex-start, space-between);
                            border-radius: clamp(1.4rem, 0.987vw + 0.421rem, 2rem);
                            background: radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                            box-shadow: 0px 6.9px 34.6px 0px rgba(66, 83, 146, 0.12);

                            .content {
                                width: clamp(14.1rem, 12.007vw + 2.189rem, 21.4rem);
                                height: 100%;
                                @include display(null, null, space-between);

                                img {
                                    width: clamp(3.6rem, 3.125vw + 0.5rem, 5.5rem);
                                    height: clamp(3.6rem, 3.125vw + 0.5rem, 5.5rem);
                                }

                                .inner--heading {
                                    width: auto;
                                    height: auto;
                                    @include display(column, flex-start, flex-start);

                                    span {
                                        @include display(null, center, flex-start);
                                        width: auto;
                                        height: auto;
                                        @include text(500, clamp(0.57rem, 0.477vw + 0.097rem, 0.86rem), normal, normal, #767A8D);

                                        img {
                                            width: clamp(0.9rem, 0.658vw + 0.247rem, 1.3rem);
                                            height: clamp(0.9rem, 0.658vw + 0.247rem, 1.3rem);
                                            margin-right: clamp(0.22rem, 0.214vw + 0.008rem, 0.35rem);
                                        }
                                    }

                                    h3 {
                                        @include text(600, clamp(0.8rem, 0.658vw + 0.147rem, 1.2rem), normal, normal, #2E303A);
                                    }
                                }
                            }
                        }

                        .hoverLine {
                            @include position(top, 50%, left, 0);
                            transform: translateX(-75%);
                            z-index: -1;
                            width: clamp(28.3rem, 24.178vw + 4.316rem, 43rem);
                            height: clamp(10.6rem, 9.046vw + 1.626rem, 16.1rem);
                        }
                    }

                    .section4 {
                        .hoverLine {
                            top: -130%;
                        }
                    }

                    .section5,
                    .section6,
                    .section7 {
                        .hoverLine {
                            top: -140%;
                        }
                    }

                    .section1,
                    .section7 {
                        margin-right: clamp(8.2rem, 7.072vw + 1.184rem, 12.5rem);
                    }

                    .section2,
                    .section6 {
                        margin-right: clamp(5.4rem, 4.77vw + 0.668rem, 8.3rem);
                    }

                    .section3,
                    .section5 {
                        margin-right: clamp(2.7rem, 2.303vw + 0.416rem, 4.1rem);
                    }
                }
            }
        }

        .pd1__section4 {
            height: 94.7rem;

            .section4__container {
                height: 63.6rem;
                margin-top: 10.7rem;
                background: linear-gradient(278deg, #0F31E6 0%, #536DF4 100%);
                @include display(column-reverse, center, flex-start);
                padding: 5.8rem 8.5rem;
                padding-top: 0;

                .content {
                    max-width: 52.2rem;
                    height: 18.8rem;
                    @include display(column, center, flex-end);

                    h2 {
                        font-size: 3.6rem;
                        line-height: 4.3rem;
                        text-align: center;
                        margin-bottom: 1.6rem;
                    }

                    p {
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        margin-bottom: 0.8rem;
                        text-align: center;
                    }

                    span {
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        text-align: center;
                    }

                }

                .image-container {
                    width: 39rem;
                    height: 100%;
                    position: relative;
                    margin-bottom: 4.8rem;

                    button {
                        top: 13.5rem;
                    }

                    .next {
                        left: 37.5rem;
                    }

                    .previous {
                        left: 1.5rem;
                    }

                    .video--container {
                        bottom: unset;
                        @include position(top, 13rem, left, 50%);
                        transform: translate(-50%, -50%);
                        width: 36rem;
                        height: 36rem;
                        border-radius: 1.4rem;
                        background: #FFF;
                        box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.16);
                        z-index: 2;
                        @include display();

                        .play--logo {
                            width: clamp(7.5rem, 4.112vw + 3.421rem, 10rem);
                            height: clamp(7.5rem, 4.112vw + 3.421rem, 10rem);
                            border-radius: 50%;
                            box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.16);
                        }
                    }

                    .bottom {
                        opacity: 1;
                        z-index: 1;
                        transform: rotate(7deg) translate(-55%, -45%);
                        box-shadow: none;
                        overflow: hidden;

                        .overlay {
                            @include position(top, 0, left, 0);
                            width: 100%;
                            height: 100%;
                            background: #8295FD;
                            z-index: 10;
                        }
                    }

                    .middle {
                        opacity: 1;
                        z-index: 2;
                        transform: rotate(0deg) translate(-50%, -50%);
                        box-shadow: none;
                        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
                    }

                    .top {
                        opacity: 1;
                        z-index: 1;
                        transform: rotate(7deg) translate(-55%, -45%);
                        box-shadow: none;
                        overflow: hidden;

                        .overlay {
                            @include position(top, 0, left, 0);
                            width: 100%;
                            height: 100%;
                            background: #8295FD;
                            z-index: 10;
                        }
                    }
                }
            }
        }

        .pd1__section5 {

            .background {
                transform: translateX(clamp(-6.4rem, calc(-14.35vw + 7.821rem), -3.2rem));
            }

            .fixedLayout {

                .image--container {
                    width: clamp(32.8rem, 24.664vw + 13.858rem, 38.3rem);
                    height: clamp(39rem, 29.148vw + 16.614rem, 45.5rem);
                    position: relative;
                }

                .mainImg1,
                .mainImg2,
                .mainImg3 {
                    width: clamp(32.8rem, 24.664vw + 13.858rem, 38.3rem);
                    // height: auto;
                }

                .mainImg1 {
                    height: clamp(39rem, 29.018vw + 16.714rem, 45.5rem);
                }

                .mainImg2,
                .mainImg3 {
                    height: clamp(34rem, 35.714vw + 6.571rem, 42rem);
                }

                .content--container {
                    width: clamp(34.8rem, 39.013vw + 4.838rem, 43.5rem);
                    margin-top: clamp(-8rem, calc(8.969vw + -14.888rem), -6rem);

                    .top--section {
                        height: clamp(6.4rem, 10.314vw + -1.521rem, 8.7rem);
                        margin-bottom: clamp(0.7rem, 2.242vw + -1.022rem, 1.2rem);
                        position: relative;

                        span {
                            @include text(300, clamp(2.4rem, 3.587vw + -0.355rem, 3.2rem), normal, -0.1rem, #FFF);
                            margin-right: clamp(2.4rem, 3.587vw + -0.355rem, 3.2rem);
                        }

                        .sec5IconImg {
                            @include position(right, clamp(-8.7rem, calc(-10.314vw + 1.521rem), -6.4rem), bottom, 0);
                            width: clamp(6.4rem, 10.314vw + -1.521rem, 8.7rem);
                            height: clamp(6.4rem, 10.314vw + -1.521rem, 8.7rem);
                        }
                    }

                    h2 {
                        width: 100%;
                        height: clamp(7rem, 8.969vw + 0.112rem, 9rem);
                        font-size: clamp(5.4rem, 6.278vw + 0.578rem, 6.8rem);
                        position: relative;
                    }
                }
            }
        }

        .pd1__section6 {
            @include display(null, center, center);
            height: 101rem;
            margin-bottom: 0;

            .section--wrapper {
                @include display(column, flex-start, space-between);
                width: 100%;
                height: auto;
                margin-top: 0;

                .image--container {
                    width: 46.3rem;
                    height: 54.1rem;

                    svg {
                        transform: scale(0.9) !important;
                        transform-origin: left;
                    }
                }

                .content--container {
                    width: auto;
                    height: auto;
                    max-width: 52.6rem;
                    @include display(column, flex-start, space-between);
                    align-self: flex-start;
                    position: relative;

                    img {
                        width: clamp(13.9rem, 10.855vw + 3.132rem, 20.5rem);
                        height: clamp(13.9rem, 8.059vw + 5.905rem, 18.8rem);
                        margin-bottom: 0;
                        @include position(right, -29%, top, 0);
                    }

                    h2 {
                        font-size: 4.8rem;
                        line-height: 5.7rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        .pd1__section7 {
            padding: clamp(4.8rem, 7.639vw + 1.133rem, 7rem) 0;

            .sec7__content--container {
                width: clamp(32.8rem, 42.157vw + 17.624rem, 50rem);
                margin-bottom: 18.2rem;

                h2 {
                    font-size: clamp(3.2rem, 0.98vw + 2.847rem, 3.6rem);
                    line-height: clamp(3.8rem, 1.225vw + 3.359rem, 4.3rem);
                    margin-bottom: clamp(0.8rem, 3.571vw + -1.943rem, 1.6rem);
                }
            }

            .sec7__image--container {
                padding: clamp(1.8rem, 2.239vw + 0.681rem, 2.4rem);
                gap: clamp(1.2rem, 2.239vw + 0.081rem, 1.8rem);
                border-radius: 1.6rem;

                @media (max-width: 500px) {
                    padding: clamp(1.6rem, 1.429vw + 1.086rem, 1.8rem);
                }

                .profiles {
                    height: clamp(14.9rem, 30.97vw + -0.585rem, 23.2rem);
                    border-radius: clamp(1rem, 0.893vw + 0.314rem, 1.2rem);

                    @media (max-width: 500px) {
                        height: clamp(13.8rem, 44.286vw + -2.143rem, 20rem);
                    }
                }

                .hover__container {
                    height: clamp(13rem, 4.104vw + 10.948rem, 14.1rem);
                    width: clamp(28.1rem, 7.09vw + 24.555rem, 30rem);
                    top: clamp(-21rem, calc(-17.857vw + -3.286rem), -17rem);

                    .text--section {
                        padding: clamp(2rem, 1.493vw + 1.254rem, 2.4rem);

                        p {
                            font-size: 1.1rem;
                        }
                    }

                    .hoverVector {
                        left: clamp(-7rem, calc(-4.464vw + -2.571rem), -6rem);
                        width: clamp(8.5rem, 6.696vw + 3.357rem, 10rem);
                        top: 60%;

                        @media (max-width: 500px) {
                            rotate: 68deg;
                        }
                    }
                }
            }
        }

        .pd1__section1 {
            height: clamp(70rem, 73.529vw + 43.529rem, 100rem);
            padding-top: 2rem;

            .content--container {
                max-width: clamp(30rem, 51.471vw + 11.471rem, 51rem);
                ;

                h1 {
                    font-size: clamp(4rem, 3.922vw + 2.588rem, 5.6rem);
                    line-height: clamp(5.7rem, 2.451vw + 4.818rem, 6.7rem);
                }

                p {
                    font-size: clamp(1.4rem, 0.49vw + 1.224rem, 1.6rem);
                    line-height: clamp(2.4rem, 1.961vw + 1.694rem, 3.2rem);
                    max-width: clamp(26.3rem, 48.284vw + 8.918rem, 46rem);
                }

            }

            .animation--container {
                width: clamp(32.6rem, 92.157vw + -0.576rem, 70.2rem);
                height: auto;
                margin-top: 7.2rem;
                scale: 1.075;
            }
        }

        .pd1__section2 {
            height: clamp(73.3rem, 60vw + 51.7rem, 82.3rem);
            position: relative;
            margin-bottom: 7rem;

            @media (max-width: 600px) {
                margin-bottom: 2rem;
            }

            h2 {
                margin-top: 8.1rem;
                width: clamp(25.9rem, 76.364vw + -1.591rem, 38.5rem);
                height: auto;
                font-size: clamp(2.4rem, 7.273vw + -0.218rem, 3.6rem);
                line-height: clamp(2.8rem, 9.091vw + -0.473rem, 4.3rem);
            }

            .mobile__container {
                right: unset;
                left: clamp(-1.6rem, 46.814vw + -18.453rem, 17.5rem);
                margin-top: 27.5rem;
                width: clamp(36rem, 25vw + 27rem, 46.2rem);
                height: clamp(42.8rem, 13.971vw + 37.771rem, 48.5rem);
                overflow: hidden;
                @include position(top, 50%, left, 50%);
                transform: translate(-50%, clamp(-40rem, calc(4.902vw + -43.765rem), -42rem));
                scale: 1;

                @media (max-width: 600px) {
                    margin-top: 25.5rem;
                }

                svg {
                    transform: scale(1.1) !important;

                    @media (max-width: 450px) {
                        transform: scale(1.05) !important;
                    }
                }
            }

            .steps__container {
                top: 74.5rem;
                right: clamp(5.5rem, 37.99vw + -8.176rem, 21rem);
                width: clamp(21.6rem, 16.912vw + 15.512rem, 28.5rem);

                @media (max-width: 600px) {
                    top: clamp(69rem, 20vw + 59rem, 71rem);
                }

                @media (max-width: 500px) {
                    top: clamp(67rem, 40vw + 49rem, 69rem);
                }

                @media (max-width: 450px) {
                    top: clamp(62rem, 55.556vw + 42rem, 67rem);
                }

                .step {
                    width: clamp(21.6rem, 16.912vw + 15.512rem, 28.5rem);
                    height: clamp(6.8rem, 5.392vw + 4.859rem, 9rem);
                    border-radius: clamp(1.7rem, 1.225vw + 1.259rem, 2.2rem);
                    border-bottom-right-radius: clamp(0.42rem, 0.343vw + 0.296rem, 0.56rem);

                    .text__section {
                        padding: clamp(1.28rem, 1.029vw + 0.909rem, 1.7rem);

                        .step--inner {
                            width: clamp(3.4rem, 2.696vw + 2.429rem, 4.5rem);
                            border-radius: clamp(0.85rem, 0.686vw + 0.603rem, 1.13rem);
                            border-bottom-right-radius: clamp(0.21rem, 0.172vw + 0.148rem, 0.28rem);
                            background: #4961E8;
                            box-shadow: 0px 16px 24px 0px rgba(73, 97, 232, 0.40);
                            @include display(column, center, center);
                            padding: clamp(0.64rem, 0.515vw + 0.455rem, 0.85rem);

                            h3 {
                                font-size: clamp(1.7rem, 1.225vw + 1.259rem, 2.2rem);
                            }

                            p {
                                font-size: clamp(0.64rem, 0.49vw + 0.464rem, 0.84rem);
                            }
                        }

                        .text {
                            width: clamp(14rem, 11.765vw + 9.965rem, 19rem);

                            h3 {
                                font-size: clamp(1.12rem, 0.882vw + 0.802rem, 1.48rem);
                            }

                            p {
                                font-size: clamp(0.74rem, 0.588vw + 0.528rem, 0.98rem);
                            }
                        }
                    }
                }

                .step.top {
                    top: clamp(1.46rem, 1.078vw + 1.072rem, 1.9rem);
                }

                .step.middle {
                    top: clamp(0.61rem, 0.515vw + 0.425rem, 0.82rem);
                }

                .mobileVector {
                    // @include position(right, -8rem, bottom, 1.6rem);
                    right: clamp(-8rem, calc(-4.902vw + -4.235rem), -6rem);
                    bottom: 19rem;
                    width: clamp(6.4rem, 5.147vw + 4.547rem, 8.5rem);
                    height: clamp(4.6rem, 3.676vw + 3.276rem, 6.1rem);
                    transform: rotate(-108deg);
                }
            }
        }

        .pd1__section3 {
            @include display(column, flex-start, flex-start);
            height: clamp(85rem, 46.569vw + 68.235rem, 104rem);

            .heading {
                max-width: clamp(23rem, 26.961vw + 13.294rem, 34rem);
                margin-top: clamp(4rem, 12.29vw + -0.42rem, 9rem);
                margin-bottom: 6rem;
                margin-right: clamp(0rem, 13.971vw + -5.029rem, 5.7rem);
                height: auto;
                align-self: flex-end;

                h2 {
                    font-size: clamp(2.4rem, 2.941vw + 1.341rem, 3.6rem);
                    line-height: clamp(2.8rem, 3.676vw + 1.476rem, 4.3rem);
                }
            }

            .image--container {
                width: 100%;
                height: 55rem;
                @include display(column, null, space-between);
                position: relative;

                .left--section {
                    width: clamp(9rem, 41.176vw + -5.824rem, 25.8rem);
                    height: clamp(9rem, 41.176vw + -5.824rem, 25.8rem);
                    @include display;
                    transform: translateX(0);
                    @include position(top, clamp(-26rem, calc(-24.57vw + -7.15rem), -16rem), left, 0);
                }

                .middle--section {
                    width: clamp(27.2rem, 34.069vw + 14.935rem, 41.1rem);
                    height: auto;
                    @include display;
                    transform: translateX(0);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        border-radius: 50%;
                        box-shadow: 0 78px 69px -52px rgba(145, 228, 255, 1);
                    }

                    .text--datsme {
                        .datsme--text {
                            font-size: clamp(2.4rem, 3.431vw + 1.165rem, 3.8rem);
                            text-shadow: 0px 3.2px 3.2px rgba(192, 226, 255, 0.50);
                        }
                    }

                    .text--description {

                        .timing {
                            font-size: clamp(1.2rem, 0.98vw + 0.847rem, 1.6rem);
                            margin-bottom: 0.3rem;
                        }

                        .timing--border {
                            width: clamp(3.2rem, 4.412vw + 1.612rem, 5rem);
                        }

                        h2 {
                            font-size: clamp(1.8rem, 2.451vw + 0.918rem, 2.8rem);
                            line-height: clamp(2.1rem, 3.186vw + 0.953rem, 3.4rem);
                            max-width: clamp(20rem, 19.608vw + 12.941rem, 28rem);
                            margin-bottom: clamp(0.4rem, 0.98vw + 0.047rem, 0.8rem);
                        }

                        p {
                            font-size: clamp(1.2rem, 0.98vw + 0.847rem, 1.6rem);
                            max-width: clamp(20rem, 9.804vw + 16.471rem, 24rem);
                            margin-bottom: clamp(2rem, 5.882vw + -0.118rem, 4.4rem);
                        }

                        .songIcon {
                            width: clamp(7rem, 9.314vw + 3.647rem, 10.8rem);
                            height: clamp(5.6rem, 7.353vw + 2.953rem, 8.6rem);
                        }
                    }
                }

                .right--section {
                    margin-top: 8.4rem;
                    height: 24.2rem;
                    width: clamp(36rem, 100vw + 0rem, 76.7rem);
                    @include display(column, flex-start, space-between);
                    max-width: clamp(36rem, 100vw + 0rem, 76.7rem);
                    position: absolute;
                    bottom: clamp(-16rem, calc(-34.314vw + 10.353rem), -2rem);
                    left: clamp(-3.2rem, -3.931vw - 0.185rem, -1.6rem);
                    overflow: hidden;

                    .wrapper__section {
                        width: auto;
                        height: 13.2rem;
                        @include display(null, flex-start, space-between);
                        overflow: scroll;
                        max-width: clamp(36rem, 100vw + 0rem, 76.7rem);
                        scrollbar-width: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .section {
                            margin-right: 1.7rem;
                            box-shadow: 0px 10px 28px 0px rgba(66, 83, 146, 0.12);
                            cursor: pointer;

                            &:last-child {
                                margin-right: 0;
                            }

                            .hoverLine {
                                display: none;
                            }
                        }

                        &.wrapper__section:first-child {
                            padding: 0 1.7rem;
                            padding-top: 2rem;
                            @include position(top, 0, left, 0);
                        }

                        &.wrapper__section:last-child {
                            @include position(bottom, -1rem, left, 0);
                        }
                    }

                    .section {
                        border-radius: 2rem;
                        width: 27.7rem;
                        height: 8.3rem;
                        @include display;
                        position: relative;

                        &--container {
                            width: 100%;
                            height: 100%;
                            padding: 1.3rem;
                            @include display(null, flex-start, space-between);
                            background: radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                            box-shadow: none;

                            .content {
                                width: 19.4rem;
                                height: 5.5rem;
                                @include display(null, null, space-between);

                                img {
                                    width: 5.5rem;
                                    height: 5.5rem;
                                }

                                .inner--heading {
                                    width: auto;
                                    height: auto;
                                    @include display(column, flex-start, flex-start);

                                    span {
                                        @include display(null, center, flex-start);
                                        width: auto;
                                        height: auto;
                                        @include text(500, 0.86rem, normal, normal, #767A8D);

                                        img {
                                            width: 1.3rem;
                                            height: 1.3rem;
                                            margin-right: 0.35rem;
                                        }
                                    }

                                    h3 {
                                        @include text(600, 1.2rem, normal, normal, #2E303A);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .pd1__section4 {
            height: clamp(84rem, 26.225vw + 74.559rem, 94.7rem);

            .section4__container {
                height: clamp(58.7rem, 12.01vw + 54.376rem, 63.6rem);
                padding: clamp(5.1rem, 1.716vw + 4.482rem, 5.8rem) clamp(2.2rem, 15.441vw + -3.359rem, 8.5rem);
                padding-top: 0;
                border-radius: clamp(3.2rem, 3.922vw + 1.788rem, 4.8rem);

                .content {
                    max-width: clamp(28.6rem, 57.843vw + 7.776rem, 52.2rem);
                    height: auto;

                    h2 {
                        font-size: 3.6rem;

                        @media (max-width: 450px) {
                            text-align: left;
                        }
                    }

                    p,
                    span {
                        font-size: clamp(1.4rem, 0.49vw + 1.224rem, 1.6rem);
                        line-height: clamp(2.1rem, 0.735vw + 1.835rem, 2.4rem);


                        @media (max-width: 450px) {
                            text-align: left;
                        }
                    }

                }

                .image-container {
                    width: clamp(28.5rem, 25.735vw + 19.235rem, 39rem);

                    button {
                        width: clamp(3.2rem, 3.922vw + 1.788rem, 4.8rem);
                        height: clamp(3.2rem, 3.922vw + 1.788rem, 4.8rem);
                        top: clamp(7rem, 15.931vw + 1.265rem, 13.5rem);
                    }

                    .next {
                        left: clamp(27rem, 25.735vw + 17.735rem, 37.5rem);
                    }

                    .previous {
                        left: 1.5rem;
                    }

                    .video--container {
                        @include position(top, clamp(7rem, 14.706vw + 1.706rem, 13rem), left, 50%);
                        width: clamp(25.8rem, 25vw + 16.8rem, 36rem);
                        height: clamp(25.8rem, 25vw + 16.8rem, 36rem);
                        border-radius: clamp(1rem, 0.98vw + 0.647rem, 1.4rem);

                        .play--logo {
                            width: clamp(5.5rem, 4.902vw + 3.735rem, 7.5rem);
                            height: clamp(5.5rem, 4.902vw + 3.735rem, 7.5rem);
                        }
                    }

                    .bottom {
                        opacity: 1;
                        z-index: 1;
                        transform: rotate(7deg) translate(-55%, -45%);
                        box-shadow: none;
                        overflow: hidden;

                        .overlay {
                            @include position(top, 0, left, 0);
                            width: 100%;
                            height: 100%;
                            background: #8295FD;
                            z-index: 10;
                        }
                    }

                    .middle {
                        opacity: 1;
                        z-index: 2;
                        transform: rotate(0deg) translate(-50%, -50%);
                        box-shadow: none;
                        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
                    }

                    .top {
                        opacity: 1;
                        z-index: 1;
                        transform: rotate(7deg) translate(-55%, -45%);
                        box-shadow: none;
                        overflow: hidden;

                        .overlay {
                            @include position(top, 0, left, 0);
                            width: 100%;
                            height: 100%;
                            background: #8295FD;
                            z-index: 10;
                        }
                    }
                }
            }
        }

        .pd1__section5 {
            height: clamp(71.6rem, 45.098vw + 55.365rem, 90rem);
            @include display;
            position: relative;

            .fixedLayout,
            .background {
                display: none;
            }

            .mobileBackground {
                display: block;
                @include position(top, 0, left, 0);
                height: 100%;
                z-index: -1;
            }

            .section5__mobileView {
                width: 100%;
                height: 100%;
                @include display(null, flex-start, flex-start);
                overflow: scroll;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                .mobile__wrapper {
                    height: 100%;
                    width: 300%;
                    @include display(null, center, center);

                    .section {
                        width: 100vw;
                        height: 100%;
                        @include display(column, center, center);
                        position: relative;

                        .content--container {
                            width: clamp(25.4rem, 18.137vw + 18.871rem, 32.8rem);
                            height: auto;
                            margin-bottom: clamp(2.8rem, 2.941vw + 1.741rem, 4rem);

                            .top--section {
                                @include display(null, flex-end, flex-start);
                                width: auto;
                                height: clamp(5.6rem, 1.961vw + 4.894rem, 6.4rem);
                                margin-bottom: 0.9rem;
                                position: relative;

                                span {
                                    @include text(300, clamp(2rem, 0.98vw + 1.647rem, 2.4rem), normal, -0.1rem, #FFF);
                                    margin-right: clamp(2rem, 0.98vw + 1.647rem, 2.4rem);
                                }

                                .sec5MobileIconImg {
                                    @include position(right, clamp(7.7rem, 10.049vw + 4.082rem, 11.8rem), bottom, 0);
                                    width: clamp(5.6rem, 1.961vw + 4.894rem, 6.4rem);
                                    height: clamp(5.6rem, 1.961vw + 4.894rem, 6.4rem);
                                }
                            }

                            h2 {
                                width: 100%;
                                @include text(200, clamp(4rem, 3.922vw + 2.588rem, 5.6rem), normal, -0.14rem, #FFF);

                                strong {
                                    font-weight: 700;
                                }

                                &:last-child {
                                    text-align: right;
                                }
                            }
                        }

                        .image--container {
                            width: auto;
                            height: auto;
                            @include display(column, center, space-between);

                            .animation--container {
                                width: clamp(25.4rem, 18.137vw + 18.871rem, 32.8rem);
                                height: auto;
                                margin-bottom: clamp(1.6rem, 1.961vw + 0.894rem, 2.4rem);
                                border-radius: 1.4rem;
                                overflow: hidden;
                                user-select: none;
                            }

                            span {
                                width: clamp(25.4rem, 18.137vw + 18.871rem, 32.8rem);
                                height: auto;
                                padding: clamp(1.6rem, 0.98vw + 1.247rem, 2rem);
                                border-radius: 1.6rem;
                                background: rgba(255, 255, 255, 0.16);
                                @include text(400, clamp(1.4rem, 0.49vw + 1.224rem, 1.6rem), 2.4rem, -0.05rem, #FFF);
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }

            .indicator {
                @include position(bottom, 0, left, 50%);
                translate: (-50% -20%);
                width: 6.4rem;
                height: 3.2rem;
                border-radius: 26.6667rem;
                background: rgba(255, 255, 255, 0.16);
                box-shadow: 0px 21.333px 32px 0px rgba(66, 83, 146, 0.12);
                gap: 0.5rem;
                @include display;
                scale: 0.6;

                .dots {
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.24);
                }

                .dots.active {
                    background: #FFF;
                }
            }
        }

        .pd1__section6 {
            @include display(null, center, center);
            height: clamp(93rem, 28.676vw + 78.976rem, 101rem);

            .section--wrapper {
                @include display(column, flex-start, space-between);
                width: 100%;
                height: auto;
                margin-top: 0;
                height: clamp(72rem, 41.667vw + 51rem, 83rem);

                .image--container {
                    width: clamp(32.8rem, 33.088vw + 20.888rem, 46.3rem);
                    height: clamp(38.3rem, 38.725vw + 24.359rem, 54.1rem);

                    @media (max-width: 450px) {
                        width: 100%;
                    }
                }

                .content--container {
                    width: auto;
                    height: auto;
                    max-width: clamp(32.4rem, 40.686vw + 17.753rem, 49rem);
                    @include display(column, flex-start, space-between);
                    align-self: flex-start;
                    position: relative;
                    margin-top: -2rem;

                    img {
                        width: clamp(10rem, 9.559vw + 6.559rem, 13.9rem);
                        height: clamp(10rem, 9.559vw + 6.559rem, 13.9rem);
                        margin-bottom: 0;
                        @include position(right, clamp(-20rem, calc(-44.118vw + 13.882rem), -2rem), top, unset);
                        bottom: clamp(-10rem, calc(14.706vw + -15.294rem), -4rem);

                        @media (max-width: 570px) {
                            bottom: -10rem;
                        }
                    }

                    h2 {
                        font-size: clamp(3.2rem, 3.922vw + 1.788rem, 4.8rem);
                        line-height: clamp(3.8rem, 4.657vw + 2.124rem, 5.7rem);
                    }
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .wrapper {
        .pd1__section6 {
            height: 100rem;
            align-items: flex-start;

            .section--wrapper {
                height: 77rem;
                margin-top: 8rem;
            }
        }
    }
}