@use '../../util' as *;

.wrapper {
    .download__section1 {
        width: 100vw;
        height: 100vh;
        max-width: 192rem;
        background: #FBFBFE;
        @include display(null, flex-start, center);
        overflow: hidden;

        &--container {
            width: 100%;
            max-width: 146rem;
            height: 100%;
            @include display(column, center, flex-start);

            .back-button {
                width: auto;
                height: auto;
                margin-top: clamp(12rem, 4.8vw + 6.72rem, 14.4rem);
                margin-bottom: clamp(5rem, 23.125vw + -32rem, 12.4rem);
                align-self: flex-start;
                margin-left: clamp(0rem, calc(-55.455vw + 87.064rem), 6.1rem);

                @media (max-width: 992px) {
                    margin-left: clamp(4.8rem, 5.804vw + 0.343rem, 6.1rem);
                }

                @media (max-width: 767px) {
                    margin-left: clamp(3rem, 4.412vw + 1.412rem, 4.8rem);
                }

                div {
                    width: auto;
                    height: auto;
                    @include display;
                }

                img {
                    width: clamp(2.4rem, 1.6vw + 0.64rem, 3.2rem);
                    height: clamp(2.4rem, 1.6vw + 0.64rem, 3.2rem);
                    rotate: 180deg;
                }

                span {
                    margin-left: 0.8rem;
                    width: auto;
                    height: auto;
                    @include text(500, clamp(1.6rem, 1.6vw + -0.16rem, 2.4rem), normal, -0.05rem, #2E303A);
                    @include display(null, center, flex-start);
                }
            }

            .download--container {
                width: clamp(72.2rem, 49.722vw + 12.533rem, 108rem);
                height: clamp(39rem, 22.083vw + 12.5rem, 54.9rem);
                position: relative;
                @include display(null, flex-start, center);
                z-index: 0;

                .bg--section {
                    @include position(top, 0, left, 50%);
                    transform: translate(-50%, 5%);
                    width: 146rem;
                    height: 72rem;
                    @include display(null, center, center);
                    z-index: 0;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    .overlay {
                        width: 101%;
                        height: 44.4rem;
                        @include position(top, 0, left, 50%);
                        transform: translate(-50%, 12rem);
                        background: linear-gradient(180deg, rgba(251, 251, 254, 0.00) 0%, #FBFBFE 100%);
                        z-index: 1;
                    }
                }

                .download--section {
                    width: 100%;
                    height: 100%;
                    padding: clamp(4.8rem, 2.222vw + 2.133rem, 6.4rem);
                    border-radius: clamp(4.8rem, 2.222vw + 2.133rem, 6.4rem);
                    background: radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                    z-index: 2;
                    @include display(null, flex-start, flex-start);
                    box-shadow: 0 64px 120px 0 rgba(66, 83, 146, 0.24);

                    .logo--section {
                        width: auto;
                        height: 100%;
                        margin-right: clamp(4.8rem, 5.139vw + -1.367rem, 8.5rem);
                        @include display(column, flex-end, flex-start);

                        .datsme--logo {
                            width: clamp(18rem, 8.333vw + 8rem, 24rem);
                            height: clamp(18rem, 8.333vw + 8rem, 24rem);
                            object-fit: cover;
                        }

                        .downloadVector {
                            width: clamp(12.5rem, 11.528vw + -1.333rem, 20.8rem);
                            height: clamp(10.2rem, 9.444vw + -1.133rem, 17rem);
                        }
                    }

                    .content--section {
                        width: auto;
                        height: 100%;
                        @include display(column, flex-start, flex-start);

                        .text {
                            width: auto;
                            height: auto;
                            @include display(column, flex-start, flex-start);
                            margin-bottom: clamp(3.8rem, 1.25vw + 2.3rem, 4.7rem);

                            h2 {
                                @include text(700, clamp(4.8rem, 2.222vw + 2.133rem, 6.4rem), clamp(5.7rem, 2.639vw + 2.533rem, 7.6rem), -0.1rem, #2E303A);
                                margin-bottom: clamp(0.8rem, 1.111vw + -0.533rem, 1.6rem);
                            }

                            p {
                                @include text(300, clamp(1.6rem, 1.111vw + 0.267rem, 2.4rem), clamp(2.4rem, 1.667vw + 0.4rem, 3.6rem), -0.05rem, #767A8D);
                            }
                        }

                        .download--button {
                            width: clamp(39rem, 31.944vw + 0.667rem, 62rem);
                            height: clamp(8rem, 7.222vw + -0.667rem, 13.2rem);
                            @include display(null, center, space-between);
                            padding: clamp(1.2rem, 1.667vw + -0.8rem, 2.4rem);
                            border-radius: 1.6rem;
                            background: #F7F7FC;

                            .download--googlePlay {
                                width: clamp(18.4rem, 12.778vw + 3.067rem, 27.6rem);
                                height: clamp(5.6rem, 3.889vw + 0.933rem, 8.4rem);
                            }

                            .lineSeparator {
                                width: 0.15rem;
                                height: clamp(5rem, 3.333vw + 1rem, 7.4rem);
                                background: #DADAF1;
                            }

                            .download--appStore {
                                width: clamp(16.7rem, 11.667vw + 2.7rem, 25.1rem);
                                height: clamp(5.6rem, 3.889vw + 0.933rem, 8.4rem);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .wrapper {
        .download__section1 {
            // min-height: 110rem;

            &--container {
                .download--container {
                    width: 64rem;
                    height: 74.6rem;
                    border-radius: 4rem;
                    background: radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);

                    .download--section {
                        padding: 7rem 4rem;
                        @include display(column, center, center);
                        box-shadow: 0 48px 120px 0 rgba(66, 83, 146, 0.16);

                        .logo--section {
                            width: auto;
                            height: auto;
                            margin-right: 0;
                            @include display;
                            margin-bottom: 2.4rem;

                            .datsme--logo {
                                width: 20rem;
                                height: 20rem;
                            }

                            .downloadVector {
                                display: none;
                            }
                        }

                        .content--section {
                            @include display(column, center, flex-start);

                            .text {
                                margin-bottom: 4.8rem;
                                @include display(column, center, flex-start);

                                h2 {
                                    font-size: 6.4rem;
                                    line-height: 7.6rem;
                                    text-align: center;
                                    margin-bottom: 1.6rem;
                                }

                                p {
                                    width: 42rem;
                                    font-size: 2.4rem;
                                    line-height: 3.6rem;
                                    text-align: center;
                                }
                            }

                            .download--button {
                                width: 46.6rem;
                                height: 9.6rem;
                                padding: 1.6rem;
                                border-radius: 1.6rem;

                                .download--googlePlay {
                                    width: 21rem;
                                    height: 6.4rem;
                                }

                                .lineSeparator {
                                    width: 0.12rem;
                                    height: 5.2rem;
                                }

                                .download--appStore {
                                    width: 19.1rem;
                                    height: 6.4rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        .download__section1 {
            // min-height: clamp(80rem, 111.94vw + 24.03rem, 110rem);

            &--container {
                .back-button {
                    margin-bottom: clamp(1.6rem, 12.687vw + -4.743rem, 5rem);
                }

                .download--container {
                    width: clamp(30rem, 126.866vw + -33.433rem, 64rem);
                    height: clamp(46.3rem, 105.597vw + -6.499rem, 74.6rem);
                    border-radius: clamp(2.4rem, 5.97vw + -0.585rem, 4rem);

                    .download--section {
                        padding: clamp(2.3rem, 6.343vw + -0.872rem, 4rem);
                        @include display(column, center, center);
                        box-shadow: 0 48px 120px 0 rgba(66, 83, 146, 0.16);

                        .logo--section {
                            margin-bottom: 0;

                            .datsme--logo {
                                width: clamp(7.2rem, 47.761vw + -16.681rem, 20rem);
                                height: clamp(7.2rem, 47.761vw + -16.681rem, 20rem);
                            }
                        }

                        .content--section {

                            .text {
                                margin-bottom: clamp(2.4rem, 8.955vw + -2.078rem, 4.8rem);

                                h2 {
                                    width: clamp(25.7rem, 112.687vw + -30.643rem, 55.9rem);
                                    font-size: clamp(2.8rem, 13.433vw + -3.916rem, 6.4rem);
                                    line-height: clamp(3.3rem, 16.045vw + -4.722rem, 7.6rem);
                                    margin-bottom: clamp(0.8rem, 2.985vw + -0.693rem, 1.6rem);
                                }

                                p {
                                    width: clamp(24.4rem, 65.299vw + -8.249rem, 41.9rem);
                                    font-size: clamp(1.4rem, 3.731vw + -0.466rem, 2.4rem);
                                    line-height: clamp(2.1rem, 5.597vw + -0.699rem, 3.6rem);
                                }
                            }

                            .download--button {
                                @include display(column, flex-start, flex-start);
                                width: auto;
                                height: auto;
                                padding: 1.6rem;
                                border-radius: 1.6rem;

                                .download--googlePlay {
                                    width: 21rem;
                                    height: 6.4rem;
                                }

                                .lineSeparator {
                                    width: 19.4rem;
                                    height: 0.2rem;
                                    margin: 1.6rem 0;
                                }

                                .download--appStore {
                                    width: 21rem;
                                    height: 6.4rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}