@use '../util' as *;

.wrapper {

    nav {
        width: 100%;
        height: 7.2rem;
        margin: 0 auto;
        position: fixed;
        top: 1.6rem;
        left: 0;
        z-index: 10000;
        background: transparent;
        padding: 0 4.3rem;

        @media (max-width: 992px) {
            padding: 0 clamp(3.2rem, 4.93vw + -0.59rem, 4.3rem);
        }

        @media (max-width: 768px) {
            padding: 0 clamp(1.6rem, 3.931vw + 0.185rem, 3.2rem);
        }

        .nav {
            width: 100%;
            height: 100%;
            padding: 1.2rem clamp(2rem, 0.43vw + 1.57rem, 2.4rem);
            max-width: calc(146rem + (clamp(2rem, 0.43vw + 1.57rem, 2.4rem) * 2));
            margin: 0 auto;
            @include display(null, null, space-between);
            border-radius: 2rem;
            background: #FFF;
            box-shadow: none;

            .logo__section {
                width: 19rem;
                height: 3.6rem;
                @include display(null, center, space-between);

                &--icon {
                    width: 3.6rem;
                    height: 3.6rem;
                }

                &--main {
                    width: 8.9rem;
                    height: 3.6rem;
                }

                &--version {
                    padding: 0.59rem 1.18rem;
                    width: 4.8rem;
                    height: 2.6rem;
                    background: rgba(72, 97, 230, 0.06);
                    border-radius: 36rem;
                    @include display;
                    @include text(600, 1.1rem, normal, null, #4861E6);
                }
            }

            &__list {
                @include display(null, null, space-between);
                width: auto;
                height: 100%;
                @include text(500, 1.6rem, 2rem, -0.02em, #2E303A);

                li {
                    @include display;
                    cursor: pointer;
                    margin-right: clamp(2.4rem, 0.86vw + 1.54rem, 3.2rem);
                    height: 100%;

                    .down-arrow {
                        width: 3rem;
                        height: 3rem;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    .activeNavTab {
                        color: #4861E6;
                    }

                    &:hover a {
                        color: #4861E6;
                        transition: color 0.2s ease-in-out;
                    }
                }

                .product {
                    position: relative;

                    .product__outer--list {
                        @include position(top, 3.9rem, left, 0);
                        width: 41.4rem;
                        height: 42rem;
                        @include display(null, flex-end, flex-end);
                    }

                    &__list {
                        padding: 1rem;
                        width: 41.4rem;
                        height: 38rem;
                        background: #FFFFFF;
                        box-shadow: 0px 4.8rem 10rem rgba(46, 48, 58, 0.16);
                        border-radius: 2.4rem;
                        @include display(column, flex-start, space-between);
                    }

                    a .product__name {
                        padding: 1.6rem;
                        width: 100%;
                        height: 11.2rem;
                        border-radius: 1.6rem;
                        background: linear-gradient(90deg, #F4F6FA 1.21%, rgba(244, 246, 250, 0) 97.69%), linear-gradient(90deg, #FBF5FE 1.21%, rgba(251, 245, 254, 0) 97.69%);
                        @include display(null, center, space-between);

                        .image {
                            width: 8rem;
                            height: 8rem;
                            border-radius: 0.8rem;
                            background: #FFFFFF;
                            @include display();
                            margin-right: 2rem;

                            img {
                                width: 6.4rem;
                                height: 6.4rem;
                            }
                        }

                        &:hover {
                            .image--learn {
                                background: #F6E8FD;
                            }

                            .image--connect {
                                background: #E6F7FF;
                            }

                            .image--manage {
                                background: #FFE8E6;
                            }
                        }

                        .content {
                            width: 25.6rem;
                            height: 7.4rem;
                            @include display(null, center, space-between);

                            &__main {
                                width: 20rem;
                                height: 7.4rem;
                                @include display(column, flex-start, space-between);

                                p {
                                    @include text(600, 2.4rem, 3rem, null, transparent);
                                    -webkit-background-clip: text;
                                    -moz-background-clip: text;
                                    background-clip: text;
                                    color: transparent;
                                }

                                .text--learn {
                                    background: linear-gradient(224.62deg, #DD94FF 0%, #DC94FF 7.81%, #DB92FF 15.63%, #D890FF 23.44%, #D48DFF 31.25%, #CF89FF 39.06%, #C985FF 46.88%, #C280FF 54.69%, #BB7AFF 62.5%, #B475FF 70.32%, #AE71FF 78.13%, #A96DFF 85.94%, #A56AFF 93.75%, #A268FF 101.57%, #A166FF 109.38%, #A066FF 117.19%), #2E303A;
                                    background-clip: text;
                                }

                                .text--connect {
                                    background: linear-gradient(240.81deg, #39C2FD 0%, #39C2FD 8.31%, #39C0FD 16.62%, #3ABEFD 24.93%, #3BBBFD 33.24%, #3CB7FD 41.55%, #3EB2FE 49.86%, #3FACFE 58.18%, #41A7FE 66.49%, #42A1FE 74.8%, #449CFF 83.11%, #4598FF 91.42%, #4695FF 99.73%, #4792FF 108.04%, #4791FF 116.35%, #4791FF 124.66%), #2E303A;
                                    background-clip: text;
                                }

                                .text--manage {
                                    background: linear-gradient(257.73deg, #FF9D8F 0%, #FF9C8F 8.38%, #FF9B8C 16.76%, #FE9887 25.13%, #FD9381 33.51%, #FC8E78 41.89%, #FB876F 50.27%, #F98064 58.65%, #F87958 67.02%, #F6714D 75.4%, #F56B43 83.78%, #F4653B 92.16%, #F36134 100.53%, #F35E30 108.91%, #F25C2D 117.29%, #F25C2C 125.67%), #2E303A;
                                    background-clip: text;
                                }

                                span {
                                    @include text(400, 1.4rem, 1.8rem, -0.02em, #2E303A);
                                }
                            }
                        }

                        img {
                            width: 3.2rem;
                            height: 3.2rem;
                        }
                    }

                    $colorsCard: linear-gradient(90deg, #FBF5FE 1.21%, rgba(251, 245, 254, 0) 97.69%),
                        linear-gradient(90deg, #F5FCFF 3.52%, rgba(245, 252, 255, 0) 100%),
                        linear-gradient(90deg, #FFF6F5 0%, rgba(255, 246, 245, 0) 100%); // Example color array

                    @for $i from 1 through length($colorsCard) {
                        a:nth-child(#{$i}) .product__name:hover {
                            background: nth($colorsCard, $i);
                        }
                    }
                }
            }

            &__download {
                @include display;
                width: 18.6rem;
                height: 4.8rem;
                position: relative;
                appearance: none;
                background: transparent;
                z-index: 1;
                border-radius: 1.6rem;
                box-shadow: none;
                transition: box-shadow 0.3s ease-in-out;

                .download__text {
                    @include display;
                    width: auto;
                    height: auto;
                    background: transparent;
                    @include text(500, 1.6rem, 2rem, -0.032rem, #2E303A);
                    z-index: 1;
                    transition: color 0.15s ease-in-out;

                    .arrow-right {
                        width: 2.4rem;
                        height: 2rem;
                        margin-left: 0.8rem;

                        path {
                            stroke: #000000;
                            stroke-width: 1.5;
                            stroke-linecap: round;
                            transition: stroke 0.15s ease-in-out;
                        }
                    }
                }

                .nav-border {
                    width: 100%;
                    height: 100%;
                    @include position(top, 0, left, 0);
                    z-index: -1;
                    background-size: cover;

                    rect {
                        fill: none;
                        stroke-width: 1.5px;
                        transition: fill 0.15s ease-in-out, stroke-width 0.15s ease-in-out;
                    }
                }

                &:hover {
                    box-shadow: 0px 12px 16px 0px rgba(72, 97, 230, 0.24);

                    .download__text {
                        color: #FFF;

                        .arrow-right {
                            path {
                                stroke: #FFF;
                            }
                        }
                    }

                    svg {
                        rect {
                            fill: #4861E6;
                            /* Change fill color on hover */
                            stroke: #4861E6;
                            /* Remove stroke border on hover */
                        }
                    }
                }
            }

            .hamburger {
                display: none;
            }
        }

        .nav.with-box-shadow {
            box-shadow: 0 1.6rem 3.2rem 0 rgba(44, 49, 74, 0.16);
            /* box-shadow when scrolled */
        }

        .mobile__menu {
            display: none;
        }

    }

    nav.navHome {
        transform: translateY(-200%);
        transition: transform 0.5s ease-in-out;
    }

    nav.translate {
        transform: translateY(0);
    }

}

@media (max-width: 992px) {
    .wrapper {
        nav {
            height: 6.4rem;

            .nav {

                .logo__section {
                    width: 17.5rem;
                    height: 3.2rem;

                    &--icon {
                        width: 3.2rem;
                        height: 3.2rem;
                    }

                    &--main {
                        width: 8rem;
                        height: 3.2rem;
                    }

                    &--version {
                        padding: 0.52rem 1.04rem;
                        width: 4.2rem;
                        height: 2.3rem;
                        border-radius: 26rem;
                        font-size: 1rem;
                    }
                }

                &__list,
                &__download {
                    display: none;
                }

                .hamburger {
                    width: 2.4rem;
                    height: 2.4rem;
                    @include display(column, flex-end, space-around);
                    cursor: pointer;
                    transform: rotate(0deg);
                    transition: transform 0.3s linear;
                    z-index: 10002;

                    .lines {
                        width: 100%;
                        border: 0.2rem solid #121212;
                        background: #121212;
                        border-radius: 1rem;
                        transition: all 0.3s linear;
                    }

                    .line-3 {
                        display: block;
                        opacity: 1;
                    }
                }

                .ham--active {
                    transform: rotate(270deg);
                    transition: transform 0.3s linear;

                    .line-1 {
                        transform: rotate(45deg) translate(0.4rem, 0.4rem);
                        transition: all 0.3s linear;
                    }

                    .line-2 {
                        transform: rotate(-45deg) translate(0.4rem, -0.4rem);
                        transition: all 0.3s linear;
                    }

                    .line-3 {
                        display: none;
                        opacity: 0;
                    }
                }
            }

            .mobile__menu {
                height: auto;
                width: 32.8rem;
                @include display(column, flex-start, space-between);
                @include position(top, 9.4rem, right, clamp(3.2rem, 14.286vw - 7.771rem, 6.4rem));
                z-index: 9999;
                padding: 3.2rem;
                border-radius: 1.6rem;
                background: #FFF;
                box-shadow: 0px 1.6rem 3.2rem 0px rgba(44, 49, 74, 0.16);
                display: none;

                &--list {
                    width: 100%;
                    height: auto;
                    @include display;

                    .mobile__list {
                        @include display(column, flex-start, space-between);
                        width: 100%;
                        height: auto;

                        li {
                            cursor: pointer;
                            margin-bottom: 2.6rem;
                            height: auto;
                            width: 100%;
                            @include display(column, flex-start, flex-start);
                            @include text(500, 2rem, normal, -0.02rem, #2E303A);

                            a {
                                width: 100%;
                                height: auto;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .down-arrow {
                                width: 3rem;
                                height: 3rem;
                            }

                            .activeNavTab {
                                color: #4861E6;
                            }

                            &:hover a {
                                color: #4861E6;
                                transition: color 0.2s ease-in-out;
                            }
                        }

                        .product {
                            position: relative;

                            .text {
                                @include display(null, center, flex-start);
                            }

                            .text--active {
                                margin-bottom: 1.6rem;
                            }

                            .menu__list {
                                width: 100%;
                                height: 32rem;
                                max-width: 33rem;
                                background: #FFFFFF;
                                @include display(column, flex-start, space-between);
                            }

                            $colorsCard: linear-gradient(90deg, #FBF5FE 1.21%, rgba(251, 245, 254, 0) 97.69%),
                                linear-gradient(90deg, #F5FCFF 3.52%, rgba(245, 252, 255, 0) 100%),
                                linear-gradient(90deg, #FFF6F5 0%, rgba(255, 246, 245, 0) 100%); // Example color array

                            @for $i from 1 through length($colorsCard) {
                                a:nth-child(#{$i}) .product__name:hover {
                                    background: nth($colorsCard, $i);
                                }
                            }

                            a .product__name {
                                padding: 1.6rem;
                                width: 100%;
                                height: 9.6rem;
                                border-radius: 1.6rem;
                                background: linear-gradient(90deg, #F4F6FA 1.21%, rgba(244, 246, 250, 0) 97.69%), linear-gradient(90deg, #FBF5FE 1.21%, rgba(251, 245, 254, 0) 97.69%);
                                @include display(null, center, flex-start);

                                .image {
                                    width: 6.4rem;
                                    height: 6.4rem;
                                    border-radius: 0.64rem;
                                    background: #FFFFFF;
                                    @include display();

                                    .mobile-learnImg {
                                        width: 5.2rem;
                                        height: 5.2rem;
                                        opacity: 1;
                                    }
                                }

                                &:hover {
                                    .image--learn {
                                        background: #F6E8FD;
                                    }

                                    .image--connect {
                                        background: #E6F7FF;
                                    }

                                    .image--manage {
                                        background: #FFE8E6;
                                    }
                                }

                                .content {
                                    margin-left: 1rem;
                                    width: 70%;
                                    height: 5.7rem;
                                    @include display(null, center, space-between);

                                    &__main {
                                        width: 100%;
                                        height: 100%;
                                        @include display(column, flex-start, center);

                                        p {
                                            @include text(600, 1.8rem, 2.2rem, null, transparent);
                                            -webkit-background-clip: text;
                                            -moz-background-clip: text;
                                            background-clip: text;
                                            color: transparent;
                                            margin-bottom: 0.8rem;
                                        }

                                        .text--learn {
                                            background: linear-gradient(224.62deg, #DD94FF 0%, #DC94FF 7.81%, #DB92FF 15.63%, #D890FF 23.44%, #D48DFF 31.25%, #CF89FF 39.06%, #C985FF 46.88%, #C280FF 54.69%, #BB7AFF 62.5%, #B475FF 70.32%, #AE71FF 78.13%, #A96DFF 85.94%, #A56AFF 93.75%, #A268FF 101.57%, #A166FF 109.38%, #A066FF 117.19%), #2E303A;
                                            background-clip: text;
                                        }

                                        .text--connect {
                                            background: linear-gradient(240.81deg, #39C2FD 0%, #39C2FD 8.31%, #39C0FD 16.62%, #3ABEFD 24.93%, #3BBBFD 33.24%, #3CB7FD 41.55%, #3EB2FE 49.86%, #3FACFE 58.18%, #41A7FE 66.49%, #42A1FE 74.8%, #449CFF 83.11%, #4598FF 91.42%, #4695FF 99.73%, #4792FF 108.04%, #4791FF 116.35%, #4791FF 124.66%), #2E303A;
                                            background-clip: text;
                                        }

                                        .text--manage {
                                            background: linear-gradient(257.73deg, #FF9D8F 0%, #FF9C8F 8.38%, #FF9B8C 16.76%, #FE9887 25.13%, #FD9381 33.51%, #FC8E78 41.89%, #FB876F 50.27%, #F98064 58.65%, #F87958 67.02%, #F6714D 75.4%, #F56B43 83.78%, #F4653B 92.16%, #F36134 100.53%, #F35E30 108.91%, #F25C2D 117.29%, #F25C2C 125.67%), #2E303A;
                                            background-clip: text;
                                        }

                                        span {
                                            @include text(400, 1.2rem, 1.5rem, -0.02em, #2E303A);
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

            }

            &+.overlay--nav {
                opacity: 0;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                background: rgba(0, 0, 0, 0.3);
                transition: opacity 0.2s ease-in-out;
            }
        }

        .nav--active {

            .nav {
                box-shadow: 0 1.6rem 3.2rem 0 rgba(44, 49, 74, 0.16);
            }

            .mobile__menu {
                display: flex;
            }

            &+.overlay--nav {
                opacity: 1;
                z-index: 9999;
            }
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        nav {
            height: 5.8rem;

            .nav {
                padding: 1.6rem;
                border-radius: 1.6rem;

                .logo__section {
                    width: 14.1rem;
                    height: 2.5rem;

                    &--icon {
                        width: 2.5rem;
                        height: 2.5rem;
                    }

                    &--main {
                        width: 6.4rem;
                        height: 2.5rem;
                    }

                    &--version {
                        padding: 0.42rem 0.84rem;
                        width: 3.4rem;
                        height: 1.94rem;
                        font-size: 0.8rem;
                    }
                }

                .hamburger {
                    padding: 0.1rem;

                    .lines {
                        border: 0.1rem solid #121212;
                    }
                }

                .ham--active {

                    .line-1 {
                        transform: rotate(45deg) translate(0.3rem, 0.3rem);
                    }

                    .line-2 {
                        transform: rotate(-45deg) translate(0.5rem, -0.5rem);
                    }
                }
            }

            .mobile__menu {
                top: 7.6rem;
                right: clamp(1.6rem, 3.931vw + 0.185rem, 3.2rem);
                width: calc(100% - (clamp(1.6rem, 3.931vw + 0.185rem, 3.2rem) * 2));
                max-height: 63.3rem;
                overflow: auto;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

        }
    }
}