@use '../../util' as *;

.wrapper {
    .pd3__Sections {
        height: 108rem;
        width: 100%;
        max-width: 146rem;
        @include display(null, flex-start);
    }

    // responsive from 1920 to 992

    .pd3__section1 {
        height: clamp(74rem, 55.92vw + 18.53rem, 108rem);
        @include display(null, center, space-between);

        .content__container {
            width: clamp(38rem, 19.74vw + 18.42rem, 50rem);
            height: clamp(42.9rem, 13.32vw + 29.68rem, 51rem);
            @include display(column, flex-start, flex-start);

            .heading--section {
                width: auto;
                height: auto;

                h1 {
                    @include text(700, clamp(4.8rem, 1.32vw + 3.49rem, 5.6rem), clamp(5.76rem, 1.58vw + 4.19rem, 6.72rem), -0.1rem, #2E303A);
                }

                span {
                    @include text(700, clamp(4.8rem, 1.32vw + 3.49rem, 5.6rem), clamp(5.76rem, 1.58vw + 4.19rem, 6.72rem), -0.1rem, transparent);
                    background: linear-gradient(258deg, #F25C2C 0%, #FF9D8F 125.67%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    position: relative;

                    .svg1--container {
                        @include position(top, clamp(2.8rem, 0.33vw + 2.47rem, 3rem), left, -4.4rem);
                        width: 7.4rem;
                        height: clamp(23.9rem, 11.02vw + 12.97rem, 30.6rem);
                        z-index: -1;

                        svg {
                            width: 100%;
                            height: 100%;
                        }

                        .svg2--container {
                            @include position(bottom, -6rem, right, -5rem);
                            width: 7.8rem;
                            height: 14.9rem;
                            z-index: -1;

                            svg {
                                width: 100%;
                                height: 100%;
                            }

                            .list--container {
                                @include position(top, -18.5%, left, clamp(5.8rem, 3.289vw + 2.537rem, 7.8rem));
                                width: clamp(29.8rem, 7.57vw + 22.29rem, 34.4rem);
                                height: 20.6rem;
                                @include display(column, flex-start, space-between);
                                background: #FFF;

                                p {
                                    width: 100%;
                                    height: auto;
                                    margin-bottom: 1.6rem;
                                    border-radius: 1.2rem;
                                    background: linear-gradient(128deg, rgba(249, 248, 252, 0.25) 4.09%, #F9F8FC 99.88%);
                                    padding: 2rem;
                                    @include text(400, 1.4rem, normal, -0.05rem, #767A8D);
                                    -webkit-text-fill-color: #767A8D;
                                    margin-top: 0;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                p {
                    margin-top: clamp(0.8rem, 2.63vw + -1.81rem, 2.4rem);
                    @include text(400, clamp(1.6rem, 0.66vw + 0.95rem, 2rem), clamp(2.4rem, 1.32vw + 1.09rem, 3.2rem), -0.05rem, #767A8D);
                }
            }
        }

        .image__container {
            width: clamp(40rem, 54.61vw + -14.17rem, 73.2rem);
            height: clamp(36.3rem, 49.18vw + -12.48rem, 66.2rem);
            margin-top: 5rem;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                scale: 1.2;
            }
        }
    }

    .pd3__section2 {
        height: 70rem;
        @include display(null, center, space-between);
        position: relative;

        h2 {
            align-self: flex-start;
            width: clamp(43.7rem, 56.908vw + -12.753rem, 78.3rem);
            @include text(700, clamp(4rem, 5.263vw + -1.221rem, 7.2rem), clamp(4.8rem, 6.579vw + -1.726rem, 8.8rem), clamp(-0.1rem, calc(-0.082vw + 0.032rem), -0.05rem), #2E303A);

            span {
                color: #4961E8;
            }
        }

        .mobile__container {
            width: clamp(43.3rem, 29.605vw + 13.932rem, 61.3rem);
            height: clamp(50.8rem, 33.059vw + 18.005rem, 70.9rem);

            svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1.1) !important;
                pointer-events: none;
            }
        }

        .steps__container {
            @include position(top, 45rem, right, clamp(43rem, 23.026vw + 20.158rem, 57rem));
            width: clamp(28.5rem, 19.572vw + 9.084rem, 40.4rem);
            height: clamp(19rem, 9.868vw + 9.211rem, 25rem);

            .step {
                @include position(top, 0, left, 0);
                width: clamp(28.5rem, 19.572vw + 9.084rem, 40.4rem);
                height: clamp(9rem, 6.25vw + 2.8rem, 12.8rem);
                border-radius: clamp(2.2rem, 1.645vw + 0.568rem, 3.2rem);
                border-bottom-right-radius: clamp(0.56rem, 0.395vw + 0.168rem, 0.8rem);
                border: 2px solid #FFF;
                background: linear-gradient(0deg, #E7E2F1 0%, #E7E2F1 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);

                .text__section {
                    width: 100%;
                    height: 100%;
                    @include display(null, null, space-between);
                    padding: clamp(1.7rem, 1.151vw + 0.558rem, 2.4rem);

                    .step--inner {
                        height: 100%;
                        width: clamp(4.5rem, 3.125vw + 1.4rem, 6.4rem);
                        border-radius: clamp(1.13rem, 0.773vw + 0.363rem, 1.6rem);
                        border-bottom-right-radius: clamp(0.28rem, 0.197vw + 0.084rem, 0.4rem);
                        background: #4961E8;
                        box-shadow: 0px 16px 24px 0px rgba(73, 97, 232, 0.40);
                        @include display(column, center, center);
                        padding: clamp(0.85rem, 0.576vw + 0.279rem, 1.2rem);

                        h3 {
                            @include text(700, clamp(2.2rem, 1.645vw + 0.568rem, 3.2rem), normal, normal, #FFF);
                        }

                        p {
                            @include text(400, clamp(0.84rem, 0.592vw + 0.253rem, 1.2rem), normal, normal, #FFF);
                            opacity: 0.75;
                        }
                    }

                    .text {
                        width: clamp(19rem, 12.829vw + 6.274rem, 26.8rem);
                        height: auto;
                        @include display(column, flex-start, center);

                        h3 {
                            @include text(700, clamp(1.48rem, 1.02vw + 0.468rem, 2.1rem), normal, normal, #2E303A);
                            margin-bottom: 0.3rem;
                        }

                        p {
                            @include text(400, clamp(0.98rem, 0.691vw + 0.295rem, 1.4rem), normal, normal, #767A8D);
                        }
                    }
                }
            }

            .step--1 {
                transform: scale(1);
            }

            .step--2 {
                transform: scale(0.9);
            }

            .step--3 {
                transform: scale(0.8);
            }

            .step.top {
                top: clamp(1.9rem, 1.316vw + 0.595rem, 2.7rem);
                background: linear-gradient(0deg, #FBFAFD 0%, #FBFAFD 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                z-index: 3;
                box-shadow: 0px 48px 64px 0px rgba(46, 48, 58, 0.12);
                transform: scale(1);
                transition: all 1s ease-in-out;
            }

            .step.middle {
                top: clamp(0.82rem, 0.461vw + 0.363rem, 1.1rem);
                background: linear-gradient(0deg, #F3F0F9 0%, #F3F0F9 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                z-index: 2;
                transform-origin: top;
                box-shadow: none;
                transform: scale(0.9);
                transition: all 1s ease-in-out;
            }

            .step.bottom {
                z-index: 1;
                transform-origin: top;
                box-shadow: none;
                transform: scale(0.8);
                animation: moveBack 1s ease-in-out;
            }

            @keyframes moveBack {

                0% {
                    background: linear-gradient(0deg, #FBFAFD 0%, #FBFAFD 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                    transform: scale(1);
                    opacity: 1;
                    z-index: 4;
                    transition: all 0.2s ease-in-out;
                }

                40% {
                    background: linear-gradient(0deg, #E7E2F1 0%, #E7E2F1 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                    transform: scale(1) translateY(100%);
                    opacity: 0;
                    z-index: 4;
                    transition: all 0s ease-in-out;
                }

                100% {
                    z-index: 1;
                    opacity: 0;
                    transform: scale(0.8);
                    transition: all 0s ease-in-out;
                }

            }

            .mobileVector {
                @include position(right, clamp(-11rem, calc(-4.934vw + -3.105rem), -8rem), bottom, clamp(0rem, calc(-2.632vw + 4.211rem), 1.6rem));
                width: clamp(8.5rem, 5.757vw + 2.789rem, 12rem);
                height: clamp(6.1rem, 4.112vw + 2.021rem, 8.6rem);
                transform: rotate(-10deg);
            }
        }
    }

    .pd3__section3 {
        height: clamp(72rem, 78.95vw + -6.32rem, 120rem);
        @include display(column, flex-start, flex-start);
        margin-top: clamp(4rem, 13.16vw + -9.05rem, 12rem);

        h2 {
            align-self: flex-start;
            width: 70%;
            @include text(700, clamp(4rem, 5.263vw + -1.221rem, 7.2rem), clamp(4.8rem, 6.579vw + -1.726rem, 8.8rem), clamp(-0.1rem, calc(-0.082vw + 0.032rem), -0.05rem), #2E303A);
            margin-bottom: 7rem;

            span {
                color: transparent;
                background: linear-gradient(258deg, #F25C2C 0%, #FF9D8F 125.67%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .video__container {
            width: 100%;
            height: clamp(44.2rem, 52.303vw + -7.684rem, 76rem);
            gap: clamp(1.86rem, 2.204vw + -0.326rem, 3.2rem);
            display: grid;
            place-items: center;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(4, 1fr);
            grid-template-areas:
                "a a a a b b b b"
                "a a a a b b b b"
                "a a a a c c c c"
                "a a a a c c c c";

            .pd3__sec3--grid {
                .animationPd3 {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: clamp(1.6rem, 2.632vw + -1.011rem, 3.2rem);
                    overflow: hidden;
                }

                h2 {
                    all: unset;
                    @include text(700, clamp(2rem, 2.632vw + -0.611rem, 3.6rem), clamp(2.8rem, 3.289vw + -0.463rem, 4.8rem), clamp(-0.1rem, calc(-0.082vw + 0.032rem), -0.05rem), transparent);
                }

                svg {
                    object-fit: cover;
                }
            }

            .reminder {
                width: clamp(42.5rem, 47.204vw + -4.326rem, 71.2rem);
                height: clamp(44.2rem, 52.303vw + -7.684rem, 76rem);
                grid-area: a;
                @include display(column);
                border-radius: clamp(1.8rem, 2.303vw + -0.484rem, 3.2rem);
                background: linear-gradient(139deg, rgba(255, 237, 215, 0.00) 0%, #FFEDD7 100%);
                padding: clamp(3.5rem, 4.112vw + -0.579rem, 6rem);

                .notes {
                    width: clamp(34.4rem, 40.789vw + -6.063rem, 59.2rem);
                    height: clamp(28.7rem, 34.046vw + -5.074rem, 49.4rem);
                    margin-bottom: clamp(3rem, 3.29vw + -0.26rem, 5rem);
                }

                h2 {
                    width: clamp(24rem, 24.67vw + -0.47rem, 39rem);
                    color: #A96D22;
                    align-self: flex-start;
                }
            }

            .circle {
                width: clamp(41.7rem, 49.342vw + -7.247rem, 71.7rem);
                height: clamp(21.1rem, 25.164vw + -3.863rem, 36.4rem);
                grid-area: b;
                @include display(null, center, space-between);
                padding: clamp(3.5rem, 4.112vw + -0.579rem, 6rem);
                border-radius: clamp(1.8rem, 2.303vw + -0.484rem, 3.2rem);
                background: linear-gradient(139deg, rgba(237, 233, 253, 0.00) 0%, #EDE9FD 100%);

                .priority {
                    width: clamp(17.3rem, 20.559vw + -3.095rem, 29.8rem);
                    height: clamp(14.1rem, 16.941vw + -2.705rem, 24.4rem);
                }

                h2 {
                    width: clamp(16.4rem, 19.572vw + -3.016rem, 28.3rem);
                    color: #6D67D1;
                }
            }

            .birthday {
                width: clamp(41.7rem, 49.342vw + -7.247rem, 71.7rem);
                height: clamp(21.1rem, 25.164vw + -3.863rem, 36.4rem);
                grid-area: c;
                @include display(null, center, space-between);
                padding: clamp(3.5rem, 4.112vw + -0.579rem, 6rem);
                border-radius: clamp(1.8rem, 2.303vw + -0.484rem, 3.2rem);
                background: linear-gradient(139deg, rgba(216, 235, 253, 0.00) 0%, #D8EBFD 100%);

                .bday {
                    width: clamp(17.3rem, 20.559vw + -3.095rem, 29.8rem);
                    height: clamp(14.1rem, 16.941vw + -2.705rem, 24.4rem);
                }

                h2 {
                    width: clamp(16.4rem, 19.572vw + -3.016rem, 28.3rem);
                    color: #619DD5;
                }
            }

        }
    }
}

@media (max-width: 992px) {
    .wrapper {
        .pd3__section1 {
            height: auto;
            @include display(column-reverse, center, center);

            .image__container {
                width: 52.4rem;
                height: 47.4rem;
                margin-top: 14.3rem;
            }

            .content__container {
                width: 45rem;
                height: 47.8rem;
                margin-left: 5rem;

                .heading--section {
                    width: 38rem;

                    h1 {
                        font-size: 5.6rem;
                        line-height: 6.72rem;
                    }

                    span {
                        font-size: 5.6rem;
                        line-height: 6.72rem;
                        margin-bottom: 0;

                        .svg1--container {
                            width: 7.4rem;
                            height: 29.3rem;
                            top: 3.4rem;

                            .svg2--container {
                                width: 7.8rem;
                                height: 14.9rem;
                                bottom: -6rem;

                                .list--container {
                                    width: 30.6rem;

                                    p {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }

                    p {
                        margin-top: 1.6rem;
                        font-size: 2rem;
                        line-height: 3.2rem;
                    }
                }
            }
        }

        .pd3__section2 {
            height: 82.3rem;
            position: relative;

            h2 {
                margin-top: 13.3rem;
                width: clamp(38.5rem, 23.214vw + 20.671rem, 43.7rem);
                height: auto;
                font-size: clamp(3.6rem, 1.786vw + 2.229rem, 4rem);
                line-height: clamp(4.3rem, 2.232vw + 2.586rem, 4.8rem);
                z-index: 10;
            }

            .mobile__container {
                @include position(top, 0, right, 0);
                margin-top: 22.7rem;
                width: 46.2rem;
                height: clamp(47.3rem, 31.25vw + 23.3rem, 54.3rem);
                overflow: hidden;
            }

            .steps__container {
                top: 54rem;
                right: clamp(42rem, 35.714vw + 14.571rem, 50rem);
            }
        }

        .pd3__section3 {
            height: auto;
            margin-bottom: 16rem;

            h2 {
                width: 80%;
            }

            .video__container {
                height: auto;
                width: 100%;
                gap: 3rem;
                grid-template-columns: repeat(1, minmax(0, 1fr));
                grid-template-rows: repeat(8, 1fr);
                grid-template-areas:
                    "a"
                    "a"
                    "a"
                    "a"
                    "b"
                    "b"
                    "c"
                    "c";

                .pd3__sec3--grid {
                    .animationPd3 {
                        border-radius: clamp(1.6rem, 3.922vw + 0.188rem, 3.2rem);
                    }
                }

                .reminder,
                .birthday,
                .circle {
                    width: 100%;
                    padding: 6rem;
                    border-radius: 3rem;
                    justify-content: space-between;

                    h2 {
                        font-size: 3.5rem;
                        line-height: 4.7rem;
                        letter-spacing: -0.1rem;
                    }
                }

                .reminder {
                    height: auto;

                    .notes {
                        width: 100%;
                        height: auto;
                        margin-bottom: 5rem;
                    }

                    h2 {
                        width: 38.5rem;
                    }
                }

                .birthday,
                .circle {
                    height: auto;

                    .priority,
                    .bday {
                        width: 50%;
                        height: auto;
                    }

                    h2 {
                        width: 27.7rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        .pd3__section1 {
            height: auto;

            .image__container {
                width: 100%;
                height: clamp(29.6rem, 43.627vw + 13.894rem, 47.4rem);
                margin-top: 12rem;
                margin-bottom: 3rem;
            }

            .content__container {
                width: 45rem;
                height: 47.8rem;
                margin-left: clamp(0rem, 12.255vw + -4.412rem, 5rem);

                @media (max-width: 500px) {
                    width: 100%;
                }

                .heading--section {
                    width: clamp(28.9rem, 22.304vw + 20.871rem, 38rem);
                    margin-left: 2rem;

                    h1 {
                        font-size: clamp(4.2rem, 3.431vw + 2.965rem, 5.6rem);
                        line-height: normal;
                    }

                    span {
                        font-size: clamp(4.2rem, 3.431vw + 2.965rem, 5.6rem);
                        line-height: normal;
                        margin-bottom: 0;

                        .svg1--container {
                            width: 74px;
                            height: clamp(21.9rem, 13.235vw + 17.135rem, 27.3rem);
                            left: -4rem;
                            top: clamp(2.5rem, 1.716vw + 1.882rem, 3.2rem);

                            .svg2--container {
                                width: 78px;
                                height: 14.9rem;
                                bottom: -6.4rem;

                                .list--container {
                                    width: clamp(25rem, 13.725vw + 20.059rem, 30.6rem);
                                    left: 28px;

                                    p {
                                        padding: 2rem clamp(1.2rem, 1.961vw + 0.494rem, 2rem);
                                        font-size: clamp(1.2rem, 0.49vw + 1.024rem, 1.4rem);
                                    }
                                }
                            }
                        }
                    }

                    p {
                        font-size: clamp(1.4rem, 1.471vw + 0.871rem, 2rem);
                        line-height: clamp(2.1rem, 2.696vw + 1.129rem, 3.2rem);
                    }
                }
            }
        }

        .pd3__section2 {
            height: clamp(73.3rem, 60vw + 51.7rem, 82.3rem);
            position: relative;

            h2 {
                margin-top: 8.1rem;
                width: clamp(25.9rem, 76.364vw + -1.591rem, 38.5rem);
                height: auto;
                font-size: clamp(2.4rem, 7.273vw + -0.218rem, 3.6rem);
                line-height: clamp(2.8rem, 9.091vw + -0.473rem, 4.3rem);
            }

            .mobile__container {
                right: unset;
                left: clamp(-1.6rem, 46.814vw + -18.453rem, 17.5rem);
                margin-top: 27.5rem;
                width: clamp(36rem, 25vw + 27rem, 46.2rem);
                height: clamp(42.8rem, 13.971vw + 37.771rem, 48.5rem);
                overflow: hidden;
                @include position(top, 50%, left, 50%);
                transform: translate(-50%, clamp(-40rem, calc(4.902vw + -43.765rem), -42rem));

                @media (max-width: 600px) {
                    margin-top: 25.5rem;
                }

                svg {
                    transform: scale(1.1) !important;

                    @media (max-width: 450px) {
                        transform: scale(1.05) !important;
                    }
                }
            }

            .steps__container {
                top: 74.5rem;
                right: clamp(5.5rem, 37.99vw + -8.176rem, 21rem);
                width: clamp(21.6rem, 16.912vw + 15.512rem, 28.5rem);

                @media (max-width: 600px) {
                    top: clamp(69rem, 20vw + 59rem, 71rem);
                }

                @media (max-width: 500px) {
                    top: clamp(67rem, 40vw + 49rem, 69rem);
                }

                @media (max-width: 450px) {
                    top: clamp(62rem, 55.556vw + 42rem, 67rem);
                }

                .step {
                    width: clamp(21.6rem, 16.912vw + 15.512rem, 28.5rem);
                    height: clamp(6.8rem, 5.392vw + 4.859rem, 9rem);
                    border-radius: clamp(1.7rem, 1.225vw + 1.259rem, 2.2rem);
                    border-bottom-right-radius: clamp(0.42rem, 0.343vw + 0.296rem, 0.56rem);

                    .text__section {
                        padding: clamp(1.28rem, 1.029vw + 0.909rem, 1.7rem);

                        .step--inner {
                            width: clamp(3.4rem, 2.696vw + 2.429rem, 4.5rem);
                            border-radius: clamp(0.85rem, 0.686vw + 0.603rem, 1.13rem);
                            border-bottom-right-radius: clamp(0.21rem, 0.172vw + 0.148rem, 0.28rem);
                            background: #4961E8;
                            box-shadow: 0px 16px 24px 0px rgba(73, 97, 232, 0.40);
                            @include display(column, center, center);
                            padding: clamp(0.64rem, 0.515vw + 0.455rem, 0.85rem);

                            h3 {
                                font-size: clamp(1.7rem, 1.225vw + 1.259rem, 2.2rem);
                            }

                            p {
                                font-size: clamp(0.64rem, 0.49vw + 0.464rem, 0.84rem);
                            }
                        }

                        .text {
                            width: clamp(14rem, 11.765vw + 9.965rem, 19rem);

                            h3 {
                                font-size: clamp(1.12rem, 0.882vw + 0.802rem, 1.48rem);
                            }

                            p {
                                font-size: clamp(0.74rem, 0.588vw + 0.528rem, 0.98rem);
                            }
                        }
                    }
                }

                .step.top {
                    top: clamp(1.46rem, 1.078vw + 1.072rem, 1.9rem);
                }

                .step.middle {
                    top: clamp(0.61rem, 0.515vw + 0.425rem, 0.82rem);
                }

                .mobileVector {
                    // @include position(right, -8rem, bottom, 1.6rem);
                    right: clamp(-8rem, calc(-4.902vw + -4.235rem), -6rem);
                    bottom: 19rem;
                    width: clamp(6.4rem, 5.147vw + 4.547rem, 8.5rem);
                    height: clamp(4.6rem, 3.676vw + 3.276rem, 6.1rem);
                    transform: rotate(-108deg);
                }
            }
        }

        .pd3__section3 {
            height: auto;
            padding: 3.7rem 0 12rem 0;
            margin: 8rem 0;

            @media (max-width: 600px) {
                margin: clamp(0rem, 33.33vw + -12rem, 8rem) 0;
            }

            h2 {
                margin-top: 2rem;
                width: clamp(25.9rem, 76.364vw + -1.591rem, 38.5rem);
                height: auto;
                font-size: clamp(2.4rem, 7.273vw + -0.218rem, 3.6rem);
                line-height: clamp(2.8rem, 9.091vw + -0.473rem, 4.3rem);
                margin-bottom: clamp(3rem, 7.35vw + 0.35rem, 6rem);
            }

            .video__container {
                width: 100%;
                gap: clamp(1.5rem, 3.676vw + 0.176rem, 3rem);

                .reminder,
                .birthday,
                .circle {
                    width: 100%;
                    padding: clamp(2.7rem, 8.088vw + -0.212rem, 6rem);
                    border-radius: clamp(1.5rem, 3.676vw + 0.176rem, 3rem);
                    justify-content: space-between;

                    h2 {
                        font-size: clamp(1.6rem, 4.657vw + -0.076rem, 3.5rem);
                        line-height: clamp(2.2rem, 6.127vw + -0.006rem, 4.7rem);
                        letter-spacing: -0.05rem;
                    }
                }

                .reminder {
                    height: clamp(34.9rem, 97.059vw + -0.041rem, 74.5rem);

                    .notes {
                        width: 100%;
                        height: clamp(22.7rem, 62.99vw + 0.024rem, 48.4rem);
                        margin-bottom: 0;
                    }

                    h2 {
                        width: clamp(18rem, 50.245vw + -0.088rem, 38.5rem);
                    }
                }

                .birthday,
                .circle {
                    height: clamp(16.7rem, 46.569vw + -0.065rem, 35.7rem);

                    .priority,
                    .bday {
                        width: clamp(13.7rem, 37.99vw + 0.024rem, 29.2rem);
                        height: clamp(11.2rem, 31.127vw + -0.006rem, 23.9rem);
                    }

                    h2 {
                        width: clamp(13rem, 36.029vw + 0.029rem, 27.7rem);
                    }
                }
            }
        }
    }
}