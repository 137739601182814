@use '../../util' as *;

.wrapper {

    .privacy,
    .terms {
        width: 100%;
        height: auto;
        @include display(column);

        .background {
            width: 100%;
            height: clamp(17rem, 36.923vw + 3.708rem, 74.6rem);
            @include display(null, flex-end, center);
            @include position(top, 0, left, 0);
            z-index: -1;

            img {
                width: 100%;
                height: clamp(12.2rem, 34.04vw + -0.05rem, 65.3rem);
            }
        }

        .content__container {
            width: 100%;
            height: auto;
            max-width: 146rem;
            @include display(column, center, flex-start);
            background: radial-gradient(103.28% 101.91% at 102.03% 101.91%, #FFFFFF 0%, #FBFCFD 100%);
            box-shadow: 0 4.8rem 12rem rgba(66, 83, 146, 0.16);
            border-radius: clamp(1.6rem, 1.54vw + 1.05rem, 4rem);
            margin-top: clamp(9.6rem, 10.06vw + 5.98rem, 25.3rem);
            margin-bottom: clamp(10rem, 3.21vw + 8.85rem, 15rem);
            padding: 0 clamp(2.7rem, 5.71vw + 0.65rem, 11.6rem);

            &--header {
                width: 100%;
                max-width: 121rem;
                height: auto;
                @include display(null, center, space-between);
                margin-top: clamp(2.8rem, 3.97vw + 1.37rem, 9rem);
                margin-bottom: clamp(4.8rem, 4.87vw + 3.05rem, 12.4rem);

                .text {
                    width: clamp(29.6rem, 32.88vw + 17.76rem, 80.9rem);
                    height: clamp(9.3rem, 7.63vw + 6.55rem, 21.2rem);
                    @include display(column, flex-start, space-between);

                    h1 {
                        @include text(700, clamp(3.6rem, 3.33vw + 2.4rem, 8.8rem), clamp(8.8rem, 1.15vw + 8.38rem, 10.6rem), -0.1rem, #2E303A);

                        @media (max-width: 992px) {
                            font-size: clamp(3.6rem, 2.85vw + 2.57rem, 5.4rem);
                            line-height: clamp(4.5rem, 3.32vw + 3.3rem, 6.6rem);
                        }
                    }

                    p {
                        width: 85%;
                        @include text(300, clamp(1.6rem, 1.03vw + 1.23rem, 3.2rem), clamp(2rem, 1.28vw + 1.54rem, 4rem), -0.1rem, #535664);
                    }

                }

                img {
                    width: auto;
                    max-width: clamp(15.9rem, 8.33vw + 12.9rem, 28.9rem);
                    height: clamp(15.9rem, 8.33vw + 12.9rem, 28.9rem);
                }

                @media (max-width: 768px) {
                    @include display(column-reverse, center, space-between);

                    .text {
                        align-items: center;

                        h1,
                        p {
                            text-align: center;
                        }
                    }
                }
            }

            &--content {
                width: 100%;
                height: auto;
                max-width: 146rem;
                margin-bottom: clamp(2.8rem, 3.97vw + 1.37rem, 9rem);

                .content__heading {
                    @include text(700, clamp(1.6rem, 1.28vw + 1.14rem, 3.6rem), 4.3rem, -0.05rem, #2E303A);
                    margin-bottom: clamp(3.2rem, 3.33vw + 2rem, 8.4rem);

                    &__font {
                        @include text(400, clamp(1.6rem, 1.28vw + 1.14rem, 3.6rem), 4.3rem, -0.05rem, #000000);
                    }

                    &--privacycolor {
                        color: #55B578;
                    }

                    &--termscolor {
                        color: #4AC6EC;
                    }
                }

                p {
                    @include text(400, clamp(1.4rem, 0.26vw + 1.31rem, 1.8rem), clamp(2.1rem, 0.38vw + 1.96rem, 2.7rem), null, #2E303A);
                    margin-bottom: clamp(2.4rem, 1.54vw + 1.85rem, 4.8rem);
                    word-break: break-all;

                    span {
                        all: inherit;
                        margin-bottom: clamp(2.1rem, 0.96vw + 1.75rem, 3.6rem);
                    }

                    strong {
                        font-weight: 700;
                    }

                    a {
                        color: blue;
                        font-weight: 700;
                    }
                }

                h3 {
                    @include text(500, clamp(2rem, 0.77vw + 1.72rem, 3.2rem), clamp(2.4rem, 0.77vw + 2.12rem, 3.6rem), null, #2E303A);
                    margin-bottom: 1.6rem;
                }

                span {
                    @include text(400, clamp(1.4rem, 0.26vw + 1.31rem, 1.8rem), clamp(2.1rem, 0.38vw + 1.96rem, 2.7rem), null, #2E303A);
                }

                ul {
                    width: 100%;
                    height: auto;
                    margin-bottom: clamp(2.1rem, 0.96vw + 1.75rem, 3.6rem);
                    max-width: 146rem;

                    li {
                        list-style: disc;
                        @include text(400, clamp(1.4rem, 0.26vw + 1.31rem, 1.8rem), clamp(2.1rem, 0.38vw + 1.96rem, 2.7rem), null, #2E303A);
                        margin-left: 2.4rem;
                    }

                    span {
                        display: block;
                        @include text(400, clamp(1.4rem, 0.26vw + 1.31rem, 1.8rem), clamp(2.1rem, 0.38vw + 1.96rem, 2.7rem), null, #2E303A);
                    }
                }

                ol {
                    list-style-position: inside;
                    margin-bottom: clamp(2.1rem, 0.96vw + 1.75rem, 3.6rem);

                    li,
                    span,
                    p {
                        word-break: break-all;
                        @include text(400, clamp(1.4rem, 0.26vw + 1.31rem, 1.8rem), clamp(2.1rem, 0.38vw + 1.96rem, 2.7rem), null, #2E303A);

                        ul {
                            li {
                                margin-left: 5rem;
                            }

                            span {
                                margin: 1.6rem 0 0 2.5rem;
                            }
                        }
                    }

                    span {
                        margin: 1.6rem 0 0 2.5rem;
                        display: block;
                    }

                    p {
                        word-break: break-all;
                        margin: 1.6rem 0 3.6rem 2.5rem;
                    }

                    ol {
                        list-style-type: inherit;
                        margin-left: 2.5rem;

                        ol {
                            list-style-position: inside;
                            list-style-type: disc;
                        }
                    }
                }
            }
        }

    }

}