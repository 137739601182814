@use '../../util' as *;

$cardStyles: (
    (background: linear-gradient(90deg, #A570FF -0.18%, #843DFF 100.18%),
        shadow: 0px 16px 24px 0px rgba(153, 94, 255, 0.40)),
    (background: linear-gradient(94deg, #F4947B 0.17%, #F06D4C 100%),
        shadow: 0px 16px 24px 0px rgba(240, 106, 72, 0.40)),
    (background: linear-gradient(87deg, #7FC8F0 5.15%, #2EA5E7 94.67%),
        shadow: 0px 16px 24px 0px rgba(46, 165, 231, 0.40)),
    (background: linear-gradient(91deg, #EE8F81 -0.18%, #E86854 100.18%),
        shadow: 0px 16px 24px 0px rgba(237, 137, 122, 0.40)),
    (background: linear-gradient(87deg, #91DBDE 5.2%, #4DC5C9 94.8%),
        shadow: 0px 16px 24px 0px rgba(77, 197, 201, 0.40)),
    (background: linear-gradient(90deg, #9BD4AD 0%, #62BC7E 99.82%),
        shadow: 0px 16px 24px 0px rgba(155, 212, 173, 0.40)),
    (background: linear-gradient(94deg, #EFBE80 0.17%, #E79B3B 100%),
        shadow: 0px 16px 24px 0px rgba(231, 155, 59, 0.40)),
    (background: linear-gradient(94deg, #7DC8F2 0.17%, #4EB5EE 100%),
        shadow: 0px 16px 24px 0px rgba(131, 203, 243, 0.40)),
    (background: linear-gradient(94deg, #ADC2F5 0%, #537FEA 99.83%),
        shadow: 0px 16px 24px 0px rgba(134, 165, 240, 0.40)),
    (background: linear-gradient(91deg, #8FE1CF -0.18%, #34C1A2 100.18%),
        shadow: 0px 16px 24px 0px rgba(74, 206, 177, 0.40)),
    (background: linear-gradient(96deg, #89E0E6 0.17%, #2ABEC9 100%),
        shadow: 0px 16px 24px 0px rgba(143, 219, 225, 0.40)),
    (background: linear-gradient(97deg, #9D93DC -0.45%, #5644C3 99.84%),
        shadow: 0px 16px 24px 0px rgba(86, 68, 195, 0.40))
);

$cardStylesMobile1: (
    (background: linear-gradient(90deg, #A570FF -0.18%, #843DFF 100.18%),
        shadow: 0px 16px 24px 0px rgba(153, 94, 255, 0.40)),
    (background: linear-gradient(94deg, #F4947B 0.17%, #F06D4C 100%),
        shadow: 0px 16px 24px 0px rgba(240, 106, 72, 0.40)),
    (background: linear-gradient(87deg, #7FC8F0 5.15%, #2EA5E7 94.67%),
        shadow: 0px 16px 24px 0px rgba(46, 165, 231, 0.40)),
    (background: linear-gradient(91deg, #EE8F81 -0.18%, #E86854 100.18%),
        shadow: 0px 16px 24px 0px rgba(237, 137, 122, 0.40)),
    (background: linear-gradient(87deg, #91DBDE 5.2%, #4DC5C9 94.8%),
        shadow: 0px 16px 24px 0px rgba(77, 197, 201, 0.40)),
    (background: linear-gradient(90deg, #9BD4AD 0%, #62BC7E 99.82%),
        shadow: 0px 16px 24px 0px rgba(155, 212, 173, 0.40))
);

$cardStylesMobile2: (
    (background: linear-gradient(94deg, #EFBE80 0.17%, #E79B3B 100%),
        shadow: 0px 16px 24px 0px rgba(231, 155, 59, 0.40)),
    (background: linear-gradient(94deg, #7DC8F2 0.17%, #4EB5EE 100%),
        shadow: 0px 16px 24px 0px rgba(131, 203, 243, 0.40)),
    (background: linear-gradient(94deg, #ADC2F5 0%, #537FEA 99.83%),
        shadow: 0px 16px 24px 0px rgba(134, 165, 240, 0.40)),
    (background: linear-gradient(91deg, #8FE1CF -0.18%, #34C1A2 100.18%),
        shadow: 0px 16px 24px 0px rgba(74, 206, 177, 0.40)),
    (background: linear-gradient(96deg, #89E0E6 0.17%, #2ABEC9 100%),
        shadow: 0px 16px 24px 0px rgba(143, 219, 225, 0.40)),
    (background: linear-gradient(97deg, #9D93DC -0.45%, #5644C3 99.84%),
        shadow: 0px 16px 24px 0px rgba(86, 68, 195, 0.40))
);

.wrapper {
    .pd2__Sections {
        height: 108rem;
        width: 100%;
        max-width: 146rem;
        @include display(null, flex-start);
    }

    .pd2__section1 {
        height: clamp(67.5rem, 66.612vw + 1.421rem, 108rem);
        @include display(null, center, space-between);

        .heading__container {
            margin-top: clamp(27.2rem, 34.211vw + -6.737rem, 48rem);
            align-self: flex-start;
            width: clamp(38.8rem, 18.421vw + 20.526rem, 50rem);
            height: auto;
            @include display(column, flex-start, flex-start);
            position: relative;

            .text__section {
                width: auto;
                height: auto;
                overflow: hidden;
                @include display(column, flex-start, flex-start);
            }

            h1 {
                @include text(700, clamp(5.6rem, 2.632vw + 2.989rem, 7.2rem), clamp(6.7rem, 3.454vw + 3.274rem, 8.8rem), -0.1rem, #2E303A);
                margin-bottom: clamp(1.4rem, 2.5vw + -1.6rem, 2.4rem);
                opacity: 0;
                transform: translateX(-100%);
                transition: transform 0.75s ease-in-out 0.5s, opacity 0.75s ease-in-out 0.5s;

                span {
                    color: #4861E6;
                }
            }

            p {
                @include text(400, clamp(1.6rem, 0.658vw + 0.947rem, 2rem), clamp(2.4rem, 1.316vw + 1.095rem, 3.2rem), -0.05rem, #767A8D);
                opacity: 0;
                transform: translateX(-100%);
                transition: transform 0.75s ease-in-out 0.5s, opacity 0.75s ease-in-out 0.5s;
            }

            .pd2Sec1H1.show,
            .pd2Sec1p.show {
                opacity: 1;
                transform: translateX(0%);
            }

            .svgLineSmall {
                @include position(top, 0, left, -0.4rem);
                width: clamp(2.5rem, 5vw + -4.5rem, 3.5rem);
                height: 33.2rem;
                margin-top: -36.8rem;
                stroke-dasharray: 445;
                stroke-dashoffset: 445;
                transition: stroke-dashoffset 1s ease-in-out;
            }

            .svgLineSmall.show {
                stroke-dashoffset: -8rem;
            }

            @media (max-width: 1400px) {
                .svgLineSmall {
                    transition: none;
                    width: 3.5rem;
                }

                .svgLineSmall.show {
                    stroke-dashoffset: clamp(-27rem, calc(46.569vw + -73.196rem), -8rem);
                }
            }

            .svgLineBig {
                @include position(bottom, clamp(-55rem, calc(-25vw + -15rem), -50rem), right, clamp(-15.1rem, calc(3.5vw + -20rem), -14.4rem));
                display: block;
                width: 70rem;
                height: clamp(45rem, 25vw + 10rem, 50rem);
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                transition: stroke-dashoffset 2s ease-in-out 1s;

                @media (max-width: 1400px) {
                    display: none;
                }
            }

            .svgLineBig.show {
                stroke-dashoffset: 400;
            }

            .svgLineBigMobile {
                display: none;

                @media (max-width: 1400px) {
                    @include position(bottom, clamp(-45rem, calc(-24.51vw + -10.686rem), -35rem), right, clamp(-2rem, calc(-7.353vw + 8.294rem), 1rem));
                    display: block;
                    width: clamp(38.8rem, 27.451vw + 11.569rem, 50rem);
                    height: clamp(32rem, 12.255vw + 19.843rem, 37rem);
                }
            }
        }

        .image__container {
            width: clamp(46.2rem, 37.829vw + 8.674rem, 69.2rem);
            height: clamp(45.9rem, 37.5vw + 8.7rem, 68.7rem);

            img,
            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .image__container {
            width: clamp(46.2rem, 37.829vw + 8.674rem, 69.2rem);
            height: clamp(45.9rem, 37.5vw + 8.7rem, 68.7rem);
        }
    }

    .pd2__section2 {
        height: clamp(75.8rem, 52.961vw + 23.263rem, 108rem);
        @include display(null, flex-start, space-between);
        position: relative;

        h2 {
            align-self: flex-start;
            margin-top: clamp(20rem, 25.329vw + -5.126rem, 35.4rem);
            width: clamp(43.7rem, 56.908vw + -12.753rem, 78.3rem);
            @include text(700, clamp(4rem, 5.263vw + -1.221rem, 7.2rem), clamp(4.8rem, 6.579vw + -1.726rem, 8.8rem), clamp(-0.1rem, calc(-0.082vw + 0.032rem), -0.05rem), #2E303A);

            span {
                color: #4961E8;
            }
        }

        .mobile__container {
            margin-top: clamp(12rem, 15.954vw + -3.826rem, 21.7rem);
            width: clamp(43.3rem, 29.605vw + 13.932rem, 61.3rem);
            height: clamp(50.8rem, 33.059vw + 18.005rem, 70.9rem);

            svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1.1) !important;
                pointer-events: none;
            }
        }

        .steps__container {
            @include position(top, clamp(43.9rem, 41.118vw + 3.111rem, 68.9rem), right, clamp(43rem, 23.026vw + 20.158rem, 57rem));
            width: clamp(28.5rem, 19.572vw + 9.084rem, 40.4rem);
            height: clamp(19rem, 9.868vw + 9.211rem, 25rem);

            .step {
                @include position(top, 0, left, 0);
                width: clamp(28.5rem, 19.572vw + 9.084rem, 40.4rem);
                height: clamp(9rem, 6.25vw + 2.8rem, 12.8rem);
                border-radius: clamp(2.2rem, 1.645vw + 0.568rem, 3.2rem);
                border-bottom-right-radius: clamp(0.56rem, 0.395vw + 0.168rem, 0.8rem);
                border: 2px solid #FFF;
                background: linear-gradient(0deg, #E7E2F1 0%, #E7E2F1 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);

                .text__section {
                    width: 100%;
                    height: 100%;
                    @include display(null, null, space-between);
                    padding: clamp(1.7rem, 1.151vw + 0.558rem, 2.4rem);

                    .step--inner {
                        height: 100%;
                        width: clamp(4.5rem, 3.125vw + 1.4rem, 6.4rem);
                        border-radius: clamp(1.13rem, 0.773vw + 0.363rem, 1.6rem);
                        border-bottom-right-radius: clamp(0.28rem, 0.197vw + 0.084rem, 0.4rem);
                        background: #4961E8;
                        box-shadow: 0px 16px 24px 0px rgba(73, 97, 232, 0.40);
                        @include display(column, center, center);
                        padding: clamp(0.85rem, 0.576vw + 0.279rem, 1.2rem);

                        h3 {
                            @include text(700, clamp(2.2rem, 1.645vw + 0.568rem, 3.2rem), normal, normal, #FFF);
                        }

                        p {
                            @include text(400, clamp(0.84rem, 0.592vw + 0.253rem, 1.2rem), normal, normal, #FFF);
                            opacity: 0.75;
                        }
                    }

                    .text {
                        width: clamp(19rem, 12.829vw + 6.274rem, 26.8rem);
                        height: 90%;
                        @include display(column, flex-start, space-between);

                        h3 {
                            @include text(700, clamp(1.48rem, 1.02vw + 0.468rem, 2.1rem), normal, normal, #2E303A);
                        }

                        p {
                            @include text(400, clamp(0.98rem, 0.691vw + 0.295rem, 1.4rem), normal, normal, #767A8D);
                        }
                    }
                }
            }

            .step--1 {
                transform: scale(1);
            }

            .step--2 {
                transform: scale(0.9);
            }

            .step--3 {
                transform: scale(0.8);
            }

            .step.top {
                top: clamp(1.9rem, 1.316vw + 0.595rem, 2.7rem);
                background: linear-gradient(0deg, #FBFAFD 0%, #FBFAFD 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                z-index: 3;
                box-shadow: 0px 48px 64px 0px rgba(46, 48, 58, 0.12);
                transform: scale(1);
                transition: all 1s ease-in-out;
            }

            .step.middle {
                top: clamp(0.82rem, 0.461vw + 0.363rem, 1.1rem);
                background: linear-gradient(0deg, #F3F0F9 0%, #F3F0F9 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                z-index: 2;
                transform-origin: top;
                box-shadow: none;
                transform: scale(0.9);
                transition: all 1s ease-in-out;
            }

            .step.bottom {
                z-index: 1;
                transform-origin: top;
                box-shadow: none;
                transform: scale(0.8);
                animation: moveBack 1s ease-in-out;
            }

            @keyframes moveBack {

                0% {
                    background: linear-gradient(0deg, #FBFAFD 0%, #FBFAFD 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                    transform: scale(1);
                    opacity: 1;
                    z-index: 4;
                    transition: all 0.2s ease-in-out;
                }

                40% {
                    background: linear-gradient(0deg, #E7E2F1 0%, #E7E2F1 100%), radial-gradient(114.94% 145.09% at 102.03% 101.91%, #FFF 0%, #FBFCFD 100%);
                    transform: scale(1) translateY(100%);
                    opacity: 0;
                    z-index: 4;
                    transition: all 0s ease-in-out;
                }

                100% {
                    z-index: 1;
                    opacity: 0;
                    transform: scale(0.8);
                    transition: all 0s ease-in-out;
                }

            }

            .mobileVector {
                @include position(right, clamp(-11rem, calc(-4.934vw + -3.105rem), -8rem), bottom, clamp(0rem, calc(-2.632vw + 4.211rem), 1.6rem));
                width: clamp(8.5rem, 5.757vw + 2.789rem, 12rem);
                height: clamp(6.1rem, 4.112vw + 2.021rem, 8.6rem);
                transform: rotate(-10deg);
            }
        }
    }

    .pd2__section3 {
        height: clamp(68rem, 65.789vw + 2.737rem, 108rem);
        @include display(null, center, space-between);

        .circle__container {
            width: clamp(46.5rem, 45.23vw + 1.632rem, 74rem);
            height: clamp(46.5rem, 45.23vw + 1.632rem, 74rem);
            position: relative;

            svg,
            img {
                @include position(top, 0, left, 0);
                width: 100%;
                height: 100%;
            }

            .sec3--circle1 {
                z-index: 1;
            }

            .sec3--circle2 {
                @include position(top, 0, left, 0);
                width: 100%;
                height: 100%;
                @include display;
                z-index: 2;
                position: relative;

                .outer--circle {
                    width: clamp(21rem, 20.39vw + 0.77rem, 33.4rem);
                    height: clamp(21rem, 20.39vw + 0.77rem, 33.4rem);
                    border-radius: 50%;
                    @include display;
                    overflow: hidden;
                    box-shadow: 0 1.6rem 2.4rem 0 rgba(0, 0, 0, 0.12);
                    position: relative;
                    backdrop-filter: blur(5rem);
                    transition: box-shadow 0.6s ease-in-out;
                    // backface-visibility: hidden;
                    // transform: translate3d(0, 0, 0);
                    // -webkit-backface-visibility: hidden;
                    // -moz-backface-visibility: hidden;
                    // -webkit-transform: translate3d(0, 0, 0);
                    // -moz-transform: translate3d(0, 0, 0);
                    will-change: transform;
                    -webkit-backdrop-filter: blur(5rem);
                    -moz-backdrop-filter: blur(5rem);
                    -ms-backdrop-filter: blur(5rem);

                    .inner--circle1 {
                        @include position(top, 22px, right, clamp(-5rem, calc(-2.47vw + -1.05rem), -3.5rem));
                        width: clamp(13.5rem, 13.16vw + 0.45rem, 21.5rem);
                        height: clamp(13.5rem, 13.16vw + 0.45rem, 21.5rem);
                        border-radius: 50%;
                        background: transparent;
                        transition: background 0.6s ease-in-out;
                        z-index: 2;
                        filter: blur(8.5rem);
                        // backface-visibility: hidden;
                        // transform: translate3d(0, 0, 0);
                        // -moz-backface-visibility: hidden;
                        // -moz-backface-visibility: hidden;
                        // -webkit-transform: translate3d(0, 0, 0);
                        // -moz-transform: translate3d(0, 0, 0);
                        will-change: transform;
                        -webkit-filter: blur(8.5rem);
                        -moz-filter: blur(8.5rem);
                        -ms-filter: blur(8.5rem);
                    }

                    .inner--circle2 {
                        @include position(bottom, -2.4rem, right, clamp(4.4rem, 2.3vw + 2.12rem, 5.8rem));
                        width: clamp(13.5rem, 13.16vw + 0.45rem, 21.5rem);
                        height: clamp(13.5rem, 13.16vw + 0.45rem, 21.5rem);
                        border-radius: 50%;
                        background: transparent;
                        transition: background 0.6s ease-in-out;
                        z-index: 3;
                        filter: blur(8.5rem);
                        // backface-visibility: hidden;
                        // transform: translate3d(0, 0, 0);
                        // -webkit-transform: translate3d(0, 0, 0);
                        // -moz-backface-visibility: hidden;
                        // -webkit-transform: translate3d(0, 0, 0);
                        // -moz-transform: translate3d(0, 0, 0);
                        will-change: transform;
                        -webkit-filter: blur(8.5rem);
                        -moz-filter: blur(8.5rem);
                        -ms-filter: blur(8.5rem);
                    }

                    .inner--circle3 {
                        @include position(top, 0px, left, 0px);
                        width: clamp(16.2rem, 15.46vw + 0.86rem, 25.6rem);
                        height: clamp(16.2rem, 15.46vw + 0.86rem, 25.6rem);
                        border-radius: 50%;
                        background: transparent;
                        transition: background 0.6s ease-in-out;
                        z-index: 1;
                        filter: blur(8.5rem);
                        // backface-visibility: hidden;
                        // transform: translate3d(0, 0, 0);
                        // -moz-transform: translate3d(0, 0, 0);
                        // -moz-backface-visibility: hidden;
                        // -webkit-transform: translate3d(0, 0, 0);
                        // -moz-transform: translate3d(0, 0, 0);
                        will-change: transform;
                        -webkit-filter: blur(8.5rem);
                        -moz-filter: blur(8.5rem);
                        -ms-filter: blur(8.5rem);
                    }
                }

                .overlay--circle {
                    @include position(top, 50%, left, 50%);
                    transform: translate(-50%, -50%);
                    width: clamp(21rem, 20.39vw + 0.77rem, 33.4rem);
                    height: clamp(21rem, 20.39vw + 0.77rem, 33.4rem);
                    border-radius: 50%;
                    z-index: 4;
                    @include display;

                    .text--container {
                        width: auto;
                        height: clamp(7.8rem, 7.57vw + 0.29rem, 12.4rem);
                        @include display(column, center, space-evenly);

                        p {
                            width: 100%;
                            height: auto;
                            text-align: center;
                            @include text(700, clamp(1.6rem, 1.64vw + -0.03rem, 2.6rem), normal, -0.05rem, #2E303A);
                        }

                        img {
                            all: unset;
                        }

                        .svgLine {
                            margin: clamp(0.9rem, 0.99vw + -0.08rem, 1.5rem) 0;
                            width: 100%;
                            height: clamp(0.8rem, 0.66vw + 0.15rem, 1.2rem);
                        }

                        .bondingStyle {
                            width: auto;
                            height: clamp(2.8rem, 2.8vw + 0.03rem, 4.5rem);
                        }
                    }
                }
            }

            .sec3--circle3 {
                // animation: rotateClockwise 4s linear 1;
                z-index: 3;
            }

            // @keyframes rotateClockwise {
            //     0% {
            //         transform: rotate(270deg);
            //     }

            //     100% {
            //         transform: rotate(360deg);
            //     }
            // }

            .sec3--circle4 {
                // animation: rotateAntiClockwise 4s linear 1;
                z-index: 3;
            }

            // @keyframes rotateAntiClockwise {
            //     0% {
            //         transform: rotate(270deg);
            //     }

            //     100% {
            //         transform: rotate(360deg);
            //     }
            // }

            .sec3--circle5 {
                // animation: rotateAntiClockwise 6s linear 1;
                z-index: 5;

                .titleDark {
                    fill: #4D4D55;
                    transition: fill 0.6s ease-in-out;
                }

                .titleLight {
                    fill: #FFFFFF;
                    transition: fill 0.6s ease-in-out;
                }

            }

            @keyframes rotateAntiClockwise {
                0% {
                    transform: rotate(-270deg);
                }

                100% {
                    transform: rotate(-360deg);
                }
            }

            .sec3--circle6 {
                z-index: 4;
                width: 100%;
                height: 100%;
                transform: rotate(-30deg);
                transition: transform 0.6s ease-in-out;
            }
        }

        .content__container {
            width: clamp(36.7rem, 37.007vw + -0.011rem, 59.2rem);
            height: clamp(41.6rem, 28.289vw + 13.537rem, 58.8rem);
            @include display(column, flex-start, space-between);

            h2 {
                @include text(300, clamp(3rem, 2.961vw + 0.063rem, 4.8rem), normal, -0.1rem, #2E303A);

                span {
                    font-weight: 700;
                }
            }

            .list--section {
                width: 100%;
                height: clamp(27.2rem, 14.474vw + 12.842rem, 36rem);
                @include display(null, flex-start, space-between);
                flex-wrap: wrap;


                @for $i from 1 through length($cardStyles) {
                    .list--element:nth-child(#{$i}):hover {
                        background: map-get(nth($cardStyles, $i), background);
                        box-shadow: map-get(nth($cardStyles, $i), shadow);
                        cursor: pointer;
                        z-index: 2;

                        p {
                            color: #FFF;
                            opacity: 1;
                            transition: color 0.15s ease-in-out;
                        }
                    }
                }

                @for $i from 1 through length($cardStyles) {
                    .list--element:nth-child(#{$i}).active {
                        background: map-get(nth($cardStyles, $i), background);
                        box-shadow: map-get(nth($cardStyles, $i), shadow);
                        cursor: pointer;
                        z-index: 2;

                        p {
                            color: #FFF;
                            opacity: 1;
                            transition: color 0.15s ease-in-out;
                        }
                    }
                }

                .list--element {
                    width: clamp(11.1rem, 11.349vw + -0.158rem, 18rem);
                    height: clamp(5.6rem, 2.632vw + 2.989rem, 7.2rem);
                    @include display;
                    border-radius: 1.2rem;
                    background: #F9F8FC;
                    cursor: pointer;
                    z-index: 1;

                    p {
                        opacity: 0.7;
                        @include text(500, clamp(1.4rem, 0.329vw + 1.074rem, 1.6rem), 4rem, -0.05rem, #767A8D);
                    }
                }

            }

            .mobile--list {
                display: none;
            }
        }
    }

    .pd2__section4 {
        height: clamp(103.3rem, 68.092vw + 35.753rem, 144.7rem);
        @include display;

        .content__container {
            width: 100%;
            height: clamp(85.4rem, 49.836vw + 35.963rem, 115.7rem);
            border-radius: 6.4rem;
            background: linear-gradient(118deg, rgba(247, 246, 251, 0.12) 0%, #F7F6FB 100%);
            @include display(column, center, flex-start);

            .heading__section {
                width: clamp(61.3rem, 79.77vw + -17.832rem, 109.8rem);
                height: clamp(12rem, 9.868vw + 2.211rem, 18rem);
                @include display(column, center, flex-start);
                margin-top: clamp(2.7rem, 10.197vw + -7.416rem, 8.9rem);
                margin-bottom: 6.4rem;

                h2 {
                    @include text(700, clamp(3.2rem, 3.947vw + -0.716rem, 5.6rem), normal, -0.1rem, #2E303A);
                    margin-bottom: 1.6rem
                }

                p {
                    @include text(400, clamp(2rem, 0.658vw + 1.347rem, 2.4rem), 3.2rem, -0.05rem, #767A8D);
                    text-align: center;
                    max-width: clamp(61.2rem, 21.382vw + 39.989rem, 74.2rem);
                    height: auto;
                }
            }

            .masonry {
                width: clamp(79.6rem, 41.447vw + 38.484rem, 104.8rem);
                height: clamp(61.6rem, 32.237vw + 29.621rem, 81.2rem);
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                gap: clamp(2.2rem, 1.316vw + 0.895rem, 3rem) clamp(3.4rem, 1.809vw + 1.605rem, 4.5rem);
                grid-template-areas:
                    "a b c"
                    "d e f";

                .masonry--item {
                    width: auto;
                    height: auto;

                    img,
                    svg {
                        height: auto;
                        object-fit: contain;
                        box-shadow: 0px 48px 64px 0px rgba(102, 114, 156, 0.24);
                        border-radius: 1.8rem;
                        width: clamp(24.2rem, 12.829vw + 11.474rem, 32rem) !important;
                    }
                }

                .item--1 {
                    grid-area: a;
                    width: clamp(24.2rem, 12.829vw + 11.474rem, 32rem) !important;
                    height: clamp(21.3rem, 11.02vw + 10.368rem, 28rem) !important;
                    position: relative;
                    border-radius: 1.8rem;
                    box-shadow: 0px 48px 64px 0px rgba(102, 114, 156, 0.24);

                    img,
                    svg {
                        @include position(top, 0, left, 0);
                        height: clamp(21.3rem, 11.02vw + 10.368rem, 28rem) !important;
                        box-shadow: none;
                    }
                }

                .item--2 {
                    grid-area: b;

                    img,
                    svg {
                        height: clamp(32.3rem, 16.941vw + 15.495rem, 42.6rem) !important;
                    }
                }

                .item--3 {
                    grid-area: c;

                    img,
                    svg {
                        height: clamp(23rem, 11.513vw + 11.579rem, 30rem) !important;
                    }
                }

                .item--4 {
                    grid-area: d;
                    position: relative;

                    img,
                    svg {
                        @include position(top, 0, left, 0);
                        margin-top: clamp(-14.9rem, calc(-5.921vw + -5.426rem), -11.3rem);
                        height: clamp(32.8rem, 17.105vw + 15.832rem, 43.2rem) !important;
                    }
                }

                .item--5 {
                    grid-area: e;

                    img,
                    svg {
                        height: clamp(16.6rem, 8.553vw + 8.116rem, 21.8rem) !important;
                    }
                }

                .item--6 {
                    grid-area: f;
                    position: relative;

                    img,
                    svg {
                        @include position(top, 0, left, 0);
                        margin-top: clamp(-12.9rem, calc(-5.099vw + -4.742rem), -9.8rem);
                        height: clamp(31.3rem, 16.283vw + 15.147rem, 41.2rem) !important;
                    }
                }
            }


        }
    }

    .pd2__section5 {
        height: clamp(47.8rem, 56.908vw + -8.653rem, 82.4rem);
        @include display;

        .wrapper__container {
            width: 100%;
            height: clamp(29.2rem, 33.059vw + -3.595rem, 49.3rem);
            @include display(null, center, space-between);

            .image__container {
                width: clamp(57rem, 64.803vw + -7.284rem, 96.4rem);
                height: 100%;
                @include display(column, flex-start, flex-start);
                position: relative;
                overflow: hidden;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                img {
                    width: 100%;
                    height: 100%;
                    @include position(top, 0, left, 0);
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                }

                .show {
                    opacity: 1;
                }
            }

            .content__container {
                width: clamp(27rem, 16.447vw + 10.684rem, 37rem);
                height: clamp(16rem, 15.789vw + 0.337rem, 25.6rem);
                @include display(null, center, space-between);

                .line__section {
                    height: 100%;
                    width: clamp(0.25rem, 0.247vw + 0.005rem, 0.4rem);
                    background: #F4F4F6;
                }

                .main__section {
                    height: 97%;
                    width: clamp(26rem, 13.816vw + 12.295rem, 34.4rem);
                    @include display(column, flex-start, space-between);
                    position: relative;

                    .pd3Sec5text {
                        @include text(400, clamp(1.6rem, 0.658vw + 0.947rem, 2rem), clamp(2rem, 1.974vw + 0.042rem, 3.2rem), -0.025rem, #767A8D);
                        cursor: pointer;
                    }

                    .active {
                        color: #2E303A;
                        font-weight: 700;
                        transition: color 0.3s ease-in-out;
                    }

                    #marker {
                        @include position(top, 0, left, clamp(-2.6rem, calc(-2.632vw + 1.611rem), -1rem));
                        width: clamp(0.25rem, 0.247vw + 0.005rem, 0.4rem);
                        height: clamp(2.8rem, 1.974vw + 0.842rem, 4rem);
                        border-radius: 15rem;
                        transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
                    }

                    .text-1.active~#marker {
                        transform: translateY(clamp(0.6rem, 0.987vw + -0.379rem, 1.2rem));
                        background: #9E9EE5;
                    }

                    .text-2.active~#marker {
                        transform: translateY(clamp(7.5rem, 7.73vw + -0.168rem, 12.2rem));
                        background: #D9A947;
                    }

                    .text-3.active~#marker {
                        transform: translateY(clamp(13.2rem, 13.158vw + 0.147rem, 21.2rem));
                        background: #4861E6;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .wrapper {
        .pd2__section1 {
            height: 92.1rem;
            @include display(column, center, center);

            .heading__container {
                width: 38.8rem;
                height: 20rem;
                margin-top: 13rem;
                margin-bottom: 6.4rem;
                @include display(column, center, center);
                align-self: center;

                h1 {
                    text-align: center;
                    font-size: 5.6rem;
                    line-height: 6.7rem;
                    margin-bottom: 1.6rem;
                }

                p {
                    text-align: center;
                }

                .svgLineSmall.show {
                    left: clamp(-23.9rem, calc(-44.643vw + 20.386rem), -13.9rem);
                    height: 44rem;
                    margin-top: -25rem;
                    stroke-dashoffset: -19rem;
                }

                .svgLineBigMobile {
                    bottom: -72rem;
                    right: clamp(-24.5rem, calc(-46.875vw + 22rem), -14rem);
                    stroke-dasharray: 441;
                    stroke-dashoffset: 0;
                }
            }

            .image__container {
                width: 46rem;
                height: 46rem;
            }
        }

        .pd2__section2 {
            height: 82.3rem;
            position: relative;

            h2 {
                margin-top: 13.3rem;
                width: clamp(38.5rem, 23.214vw + 20.671rem, 43.7rem);
                height: auto;
                font-size: clamp(3.6rem, 1.786vw + 2.229rem, 4rem);
                line-height: clamp(4.3rem, 2.232vw + 2.586rem, 4.8rem);
                z-index: 10;
            }

            .mobile__container {
                @include position(top, 0, right, 0);
                margin-top: 29.7rem;
                width: 46.2rem;
                height: clamp(47.3rem, 31.25vw + 23.3rem, 54.3rem);
                overflow: hidden;
            }

            .steps__container {
                top: 61rem;
                right: clamp(42rem, 35.714vw + 14.571rem, 50rem);
            }
        }

        .pd2__section3 {
            height: 106.8rem;
            @include display(column, center, space-between);
            position: relative;

            .circle__container {
                @include position(top, 20.4rem, left, 50%);
                width: 52.8rem;
                height: 52.8rem;
                transform: translate(-50%, 0);

                .sec3--circle2 {

                    .outer--circle {
                        width: 23.8rem;
                        height: 23.8rem;

                        .inner--circle1 {
                            @include position(top, 22px, right, clamp(-5rem, calc(-2.47vw + -1.05rem), -3.5rem));
                            width: 15.3rem;
                            height: 15.3rem;
                        }

                        .inner--circle2 {
                            @include position(bottom, -2.4rem, right, clamp(4.4rem, 2.3vw + 2.12rem, 5.8rem));
                            width: 15.3rem;
                            height: 15.3rem;
                        }

                        .inner--circle3 {
                            @include position(top, 0px, left, 0px);
                            width: 18.4rem;
                            height: 18.4rem;
                        }
                    }

                    .overlay--circle {
                        width: 23.8rem;
                        height: 23.8rem;

                        .text--container {
                            height: 8.8rem;

                            p {
                                font-size: 1.8rem;
                            }

                            .svgLine {
                                margin: 1.1rem 0;
                                height: clamp(0.8rem, 0.66vw + 0.15rem, 1.2rem);
                            }

                            .bondingStyle {
                                height: 3rem;
                            }
                        }
                    }
                }
            }

            .content__container {
                width: 70.4rem;
                height: 100%;
                margin-top: 8.8rem;
                margin-bottom: 8.8rem;
                align-items: center;

                h2 {
                    width: 48.5rem;
                    height: auto;
                    text-align: center;
                    font-size: 3.2rem;
                    line-height: normal;
                }

                .desktop--list {
                    display: flex;
                }

                .list--section {
                    height: 20rem;

                    .list--element {
                        width: 16.4rem;
                        height: 5.6rem;
                    }
                }

                .mobile--list {
                    display: none;
                }
            }
        }

        .pd2__section4 {
            height: 146rem;

            .content__container {
                width: 70rem;
                height: 131rem;

                .masonry {
                    width: 61.5rem;
                    height: 100rem;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr 1fr 1fr;
                    gap: 2.4rem;
                    grid-template-areas: "a b"
                        "d e"
                        "c f";

                    .masonry--item {
                        max-width: 29.5rem;

                        img,
                        svg {
                            width: 100% !important;
                            border-radius: 2rem;
                        }
                    }

                    .item--1 {
                        z-index: 6;
                        height: 25.8rem !important;
                        width: 100% !important;
                        max-width: 29.5rem;

                        img,
                        svg {
                            height: 25.8rem !important;
                        }
                    }

                    .item--2 {
                        z-index: 5;

                        img,
                        svg {
                            height: 39.2rem !important;
                        }
                    }

                    .item--3 {
                        position: relative;
                        z-index: 1;

                        img,
                        svg {
                            @include position(top, 0, left, 0);
                            margin-top: -1.6rem;
                            height: 28.3rem !important;
                        }
                    }

                    .item--4 {
                        z-index: 4;

                        img,
                        svg {
                            @include position(top, 0, left, 0);
                            margin-top: -13.5rem;
                            height: 39.8rem !important;
                        }
                    }

                    .item--5 {
                        z-index: 3;

                        img,
                        svg {
                            height: 20.1rem !important;
                        }
                    }

                    .item--6 {
                        z-index: 2;

                        img,
                        svg {
                            @include position(top, 0, left, 0);
                            margin-top: -7.7rem;
                            height: 37.9rem !important;
                        }
                    }
                }
            }
        }

        .pd2__section5 {
            height: 60rem;

            .wrapper__container {
                width: 70.4rem;
                height: 47rem;
                @include display(column, center, space-between);

                .image__container {
                    width: 70.4rem;
                    max-height: 36rem;
                    @include display(row, flex-start, flex-start);

                    img {
                        min-width: 70.4rem;
                    }
                }

                .content__container {
                    width: 100%;
                    height: 6.1rem;
                    @include display(column-reverse, flex-start, space-between);

                    .line__section {
                        width: 100%;
                        height: 2px;
                    }

                    .main__section {
                        width: 100%;
                        height: 4rem;
                        @include display(row, center, space-between);

                        .pd3Sec5text {
                            width: 22.4rem;
                            height: 100%;
                            font-size: 1.4rem;
                            line-height: 2rem;
                            letter-spacing: -0.03rem;
                        }

                        #marker {
                            @include position(top, unset, left, unset);
                            @include position(bottom, -2.1rem, left, 0rem);
                            width: 22rem;
                            height: 2px;
                        }

                        .text-1.active~#marker {
                            transform: translateX(0);
                            background: #9E9EE5;
                        }

                        .text-2.active~#marker {
                            transform: translateX(24rem);
                            background: #D9A947;
                        }

                        .text-3.active~#marker {
                            transform: translateX(48rem);
                            background: #4861E6;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        .pd2__section1 {
            height: clamp(80rem, 73.333vw + 53.6rem, 92.1rem);

            .heading__container {
                width: clamp(35rem, 23.03vw + 26.709rem, 38.8rem);
                height: clamp(17.4rem, 15.758vw + 11.727rem, 20rem);
                margin-top: clamp(11.8rem, 7.273vw + 9.182rem, 13rem);

                h1 {
                    font-size: clamp(4.8rem, 6.061vw + 2.618rem, 5.8rem);
                    line-height: clamp(5.7rem, 6.061vw + 3.518rem, 6.7rem);
                }

                p {
                    font-size: clamp(1.4rem, 1.212vw + 0.964rem, 1.6rem);
                    text-align: center;
                }

                .svgLineSmall,
                .svgLineBig,
                .svgLineBigMobile {
                    display: none
                }
            }

            .image__container {
                width: clamp(32rem, 84.848vw + 1.455rem, 46rem);
                height: clamp(32rem, 84.848vw + 1.455rem, 46rem);
            }
        }

        .pd2__section2 {
            height: clamp(73.3rem, 60vw + 51.7rem, 82.3rem);
            position: relative;

            h2 {
                margin-top: 8.1rem;
                width: clamp(25.9rem, 76.364vw + -1.591rem, 38.5rem);
                height: auto;
                font-size: clamp(2.4rem, 7.273vw + -0.218rem, 3.6rem);
                line-height: clamp(2.8rem, 9.091vw + -0.473rem, 4.3rem);
            }

            .mobile__container {
                right: unset;
                left: clamp(-1.6rem, 46.814vw + -18.453rem, 17.5rem);
                margin-top: 25.3rem;
                width: clamp(36rem, 25vw + 27rem, 46.2rem);
                height: clamp(42.8rem, 13.971vw + 37.771rem, 48.5rem);
                overflow: hidden;
                @include position(top, 50%, left, 50%);
                transform: translate(-50%, clamp(-40rem, calc(4.902vw + -43.765rem), -42rem));

                svg {
                    transform: scale(1.1) !important;

                    @media (max-width: 450px) {
                        transform: scale(1.05) !important;
                    }
                }
            }

            .steps__container {
                top: clamp(71rem, 11.905vw + 63.857rem, 73rem);
                right: clamp(5.5rem, 37.99vw + -8.176rem, 21rem);
                width: clamp(21.6rem, 16.912vw + 15.512rem, 28.5rem);

                @media (max-width: 600px) {
                    top: clamp(69rem, 20vw + 59rem, 71rem);
                }

                @media (max-width: 500px) {
                    top: clamp(67rem, 40vw + 49rem, 69rem);
                }

                @media (max-width: 450px) {
                    top: clamp(62rem, 55.556vw + 42rem, 67rem);
                }

                .step {
                    width: clamp(21.6rem, 16.912vw + 15.512rem, 28.5rem);
                    height: clamp(6.8rem, 5.392vw + 4.859rem, 9rem);
                    border-radius: clamp(1.7rem, 1.225vw + 1.259rem, 2.2rem);
                    border-bottom-right-radius: clamp(0.42rem, 0.343vw + 0.296rem, 0.56rem);

                    .text__section {
                        padding: clamp(1.28rem, 1.029vw + 0.909rem, 1.7rem);

                        .step--inner {
                            width: clamp(3.4rem, 2.696vw + 2.429rem, 4.5rem);
                            border-radius: clamp(0.85rem, 0.686vw + 0.603rem, 1.13rem);
                            border-bottom-right-radius: clamp(0.21rem, 0.172vw + 0.148rem, 0.28rem);
                            background: #4961E8;
                            box-shadow: 0px 16px 24px 0px rgba(73, 97, 232, 0.40);
                            @include display(column, center, center);
                            padding: clamp(0.64rem, 0.515vw + 0.455rem, 0.85rem);

                            h3 {
                                font-size: clamp(1.7rem, 1.225vw + 1.259rem, 2.2rem);
                            }

                            p {
                                font-size: clamp(0.64rem, 0.49vw + 0.464rem, 0.84rem);
                            }
                        }

                        .text {
                            width: clamp(14rem, 11.765vw + 9.965rem, 19rem);

                            h3 {
                                font-size: clamp(1.12rem, 0.882vw + 0.802rem, 1.48rem);
                            }

                            p {
                                font-size: clamp(0.74rem, 0.588vw + 0.528rem, 0.98rem);
                            }
                        }
                    }
                }

                .step.top {
                    top: clamp(1.46rem, 1.078vw + 1.072rem, 1.9rem);
                }

                .step.middle {
                    top: clamp(0.61rem, 0.515vw + 0.425rem, 0.82rem);
                }

                .mobileVector {
                    // @include position(right, -8rem, bottom, 1.6rem);
                    right: clamp(-8rem, calc(-4.902vw + -4.235rem), -6rem);
                    bottom: 19rem;
                    width: clamp(6.4rem, 5.147vw + 4.547rem, 8.5rem);
                    height: clamp(4.6rem, 3.676vw + 3.276rem, 6.1rem);
                    transform: rotate(-108deg);
                }
            }
        }

        .pd2__section3 {
            height: clamp(80.5rem, 109.583vw + 41.05rem, 106.8rem);
            @include display(column, center, space-between);
            position: relative;

            .circle__container {
                @include position(top, clamp(22.4rem, 10.526vw + 18.611rem, 24.4rem), 50%);
                width: clamp(32.7rem, 83.75vw + 2.55rem, 52.8rem);
                height: clamp(32.7rem, 83.75vw + 2.55rem, 52.8rem);
                overflow: hidden;

                .sec3--circle2 {

                    .outer--circle {
                        width: clamp(15rem, 21.57vw + 7.24rem, 23.8rem);
                        height: clamp(15rem, 21.57vw + 7.24rem, 23.8rem);

                        .inner--circle1 {
                            @include position(top, clamp(1.2rem, 2.45vw + 0.32rem, 2.2rem), right, clamp(-3.5rem, calc(-1.72vw + -2.18rem), -2.8rem));
                            width: clamp(9.6rem, 14.22vw + 4.48rem, 15.4rem);
                            height: clamp(9.6rem, 14.22vw + 4.48rem, 15.4rem);
                            filter: blur(5rem);
                        }

                        .inner--circle2 {
                            @include position(bottom, clamp(-1.2rem, -2.94vw + -0.14rem, -2.4rem), right, clamp(2.6rem, 4.41vw + 1.01rem, 4.4rem));
                            width: clamp(9.6rem, 14.22vw + 4.48rem, 15.4rem);
                            height: clamp(9.6rem, 14.22vw + 4.48rem, 15.4rem);
                            filter: blur(5rem);
                        }

                        .inner--circle3 {
                            @include position(top, 0px, left, 0px);
                            width: clamp(11.5rem, 16.91vw + 5.41rem, 18.4rem);
                            height: clamp(11.5rem, 16.91vw + 5.41rem, 18.4rem);
                            filter: blur(5rem);
                        }
                    }

                    .overlay--circle {
                        width: clamp(15rem, 21.57vw + 7.24rem, 23.8rem);
                        height: clamp(15rem, 21.57vw + 7.24rem, 23.8rem);

                        .text--container {
                            height: clamp(5.6rem, 7.84vw + 2.78rem, 8.8rem);

                            p {
                                font-size: clamp(1.2rem, 1.47vw + 0.67rem, 1.8rem);
                            }

                            .svgLine {
                                height: clamp(0.6rem, 0.49vw + 0.42rem, 0.8rem);
                            }

                            .bondingStyle {
                                width: clamp(10rem, 12.99vw + 5.32rem, 15.3rem);
                                height: 3rem;
                            }
                        }
                    }
                }
            }

            .content__container {
                width: 100%;
                height: 100%;
                position: relative;

                h2 {
                    width: clamp(29.2rem, 80.417vw + 0.25rem, 48.5rem);
                    font-size: clamp(2.4rem, 3.333vw + 1.2rem, 3.2rem);
                }

                .desktop--list {
                    display: none;
                }

                .mobile--list {
                    @include display(column, flex-start, space-between);
                    width: clamp(36rem, 100vw + 0rem, 76.7rem);
                    height: auto;
                    max-width: clamp(36rem, 100vw + 0rem, 76.7rem);
                    @include position(bottom, -7rem, left, clamp(-3.2rem, calc(-3.931vw + -0.185rem), -1.6rem));

                    .wrapper__section {
                        width: 100%;
                        overflow: scroll;
                        scrollbar-width: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        &:last-child {
                            margin-top: -1rem;
                        }
                    }

                    .list__section {
                        @include display(null, flex-start, space-between);
                        width: clamp(82.2rem, 3.931vw + 80.785rem, 83.8rem);
                        height: 9rem;

                        .list--element {
                            width: 12.6rem;
                            height: 5.6rem;
                            @include display;
                            border-radius: 1.2rem;
                            background: #F9F8FC;

                            p {
                                @include text(500, 1.4rem, 4rem, -0.05rem, #767A8D);
                            }
                        }
                    }

                    .section--1 {
                        padding-left: clamp(1.6rem, 3.931vw + 0.185rem, 3.2rem);


                        @for $i from 1 through length($cardStylesMobile1) {
                            .list--element:nth-child(#{$i}):hover {
                                background: map-get(nth($cardStylesMobile1, $i), background);
                                box-shadow: map-get(nth($cardStylesMobile1, $i), shadow);
                                cursor: pointer;

                                p {
                                    color: #FFF;
                                    opacity: 1;
                                    transition: color 0.15s ease-in-out;
                                }
                            }
                        }

                        @for $i from 1 through length($cardStylesMobile1) {
                            .list--element:nth-child(#{$i}).active {
                                background: map-get(nth($cardStylesMobile1, $i), background);
                                box-shadow: map-get(nth($cardStylesMobile1, $i), shadow);
                                cursor: pointer;

                                p {
                                    color: #FFF;
                                    opacity: 1;
                                    transition: color 0.15s ease-in-out;
                                }
                            }
                        }
                    }

                    .section--2 {
                        padding-right: clamp(1.6rem, 3.931vw + 0.185rem, 3.2rem);

                        @for $i from 1 through length($cardStylesMobile2) {
                            .list--element:nth-child(#{$i}):hover {
                                background: map-get(nth($cardStylesMobile2, $i), background);
                                box-shadow: map-get(nth($cardStylesMobile2, $i), shadow);
                                cursor: pointer;

                                p {
                                    color: #FFF;
                                    opacity: 1;
                                    transition: color 0.15s ease-in-out;
                                }
                            }
                        }

                        @for $i from 1 through length($cardStylesMobile2) {
                            .list--element:nth-child(#{$i}).active {
                                background: map-get(nth($cardStylesMobile2, $i), background);
                                box-shadow: map-get(nth($cardStylesMobile2, $i), shadow);
                                cursor: pointer;

                                p {
                                    color: #FFF;
                                    opacity: 1;
                                    transition: color 0.15s ease-in-out;
                                }
                            }
                        }
                    }
                }
            }
        }

        .pd2__section4 {
            height: clamp(124.3rem, 129.167vw + 46.8rem, 146rem);

            .content__container {
                width: 100%;
                height: clamp(116rem, 101.19vw + 55.29rem, 133rem);
                border-radius: clamp(4.8rem, 9.524vw + -0.914rem, 6.4rem);

                .masonry {
                    width: clamp(50.5rem, 65.476vw + 11.214rem, 61.5rem);
                    height: clamp(83rem, 101.19vw + 22.286rem, 100rem);
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr 1fr 1fr;
                    gap: 2.4rem;
                    grid-template-areas: "a b"
                        "d e"
                        "c f";

                    .masonry--item {
                        max-width: clamp(24rem, 32.738vw + 4.357rem, 29.5rem) !important;

                        img,
                        svg {
                            width: 100%;
                            border-radius: clamp(1.8rem, 1.19vw + 1.086rem, 2rem);
                        }
                    }

                    .item--1 {
                        z-index: 6;
                        height: clamp(21rem, 28.571vw + 3.857rem, 25.8rem) !important;

                        img,
                        svg {
                            height: clamp(21rem, 28.571vw + 3.857rem, 25.8rem) !important;
                        }
                    }

                    .item--2 {
                        z-index: 5;

                        img,
                        svg {
                            height: clamp(32rem, 42.857vw + 6.286rem, 39.2rem) !important;
                        }
                    }

                    .item--3 {
                        position: relative;
                        z-index: 1;

                        img,
                        svg {
                            @include position(top, 0, left, 0);
                            margin-top: -1.7rem;
                            height: clamp(23rem, 31.548vw + 4.071rem, 28.3rem) !important;
                        }
                    }

                    .item--4 {
                        z-index: 4;

                        img,
                        svg {
                            @include position(top, 0, left, 0);
                            margin-top: clamp(-13.5rem, calc(-13.69vw + -2.986rem), -11.2rem);
                            height: clamp(32.4rem, 44.048vw + 5.971rem, 39.8rem) !important;
                        }
                    }

                    .item--5 {
                        z-index: 3;

                        img,
                        svg {
                            height: clamp(16.4rem, 22.024vw + 3.186rem, 20.1rem) !important;
                        }
                    }

                    .item--6 {
                        z-index: 2;

                        img,
                        svg {
                            @include position(top, 0, left, 0);
                            margin-top: clamp(-7.7rem, calc(-6.548vw + -2.671rem), -6.6rem);
                            height: clamp(30.9rem, 41.667vw + 5.9rem, 37.9rem) !important;
                        }
                    }
                }

                .heading__section {
                    width: clamp(47.9rem, 79.762vw + 0.043rem, 61.3rem);
                    height: auto;
                    @include display(column, center, flex-start);
                    margin-top: clamp(2.7rem, 10.197vw + -7.416rem, 8.9rem);
                    margin-bottom: 6.4rem;

                    h2 {
                        width: clamp(32.1rem, 173.81vw + -72.186rem, 61.3rem);
                        @include text(700, clamp(3.2rem, 3.947vw + -0.716rem, 5.6rem), normal, -0.1rem, #2E303A);
                        margin-bottom: 1.6rem;
                        text-align: center;
                    }

                    p {
                        width: clamp(47.9rem, 79.762vw + 0.043rem, 61.3rem);
                        @include text(400, clamp(2rem, 0.658vw + 1.347rem, 2.4rem), 3.2rem, -0.05rem, #767A8D);
                        text-align: center;
                        max-width: clamp(61.2rem, 21.382vw + 39.989rem, 74.2rem);
                        height: auto;
                    }
                }
            }
        }

        .pd2__section5 {
            height: clamp(50rem, 59.88vw + 14.072rem, 60rem);

            .wrapper__container {
                width: 100%;
                height: clamp(40rem, 41.916vw + 14.85rem, 47rem);
                @include display(column, center, space-between);
                overflow: hidden;

                .image__container {
                    width: 100%;
                    max-height: clamp(30rem, 35.928vw + 8.443rem, 36rem);
                    @include display(row, flex-start, flex-start);

                    img {
                        min-width: 100%;
                    }
                }

                .content__container {
                    width: 100%;
                    height: 6.1rem;
                    @include display(column-reverse, flex-start, space-between);
                    overflow: scroll;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .line__section {
                        width: 70.4rem;
                        height: 2px;
                    }

                    .main__section {
                        width: 70.4rem;
                        height: 4rem;
                        @include display(row, center, space-between);
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .wrapper {
        .pd2__section4 {
            height: 257rem;
            margin-bottom: 3rem;

            @media (max-width: 380px) {
                height: 253rem;
            }

            .content__container {
                width: 100%;
                height: 241rem;
                border-radius: 3.2rem;

                .masonry {
                    width: 29.6rem;
                    height: clamp(83rem, 101.19vw + 22.286rem, 100rem);
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
                    gap: 2.4rem;
                    grid-template-areas: "a"
                        "d"
                        "c"
                        "b"
                        "e"
                        "f";

                    .masonry--item {
                        max-width: 29.6rem !important;

                        img,
                        svg {
                            width: 100%;
                            border-radius: clamp(1.8rem, 1.19vw + 1.086rem, 2rem);
                        }
                    }

                    .item--1 {
                        z-index: 6;
                        height: 25.8rem !important;

                        img,
                        svg {
                            height: 25.8rem !important;
                        }
                    }

                    .item--2 {
                        z-index: 3;

                        img,
                        svg {
                            height: 39.2rem !important;
                        }
                    }

                    .item--3 {
                        position: relative;
                        z-index: 4;

                        img,
                        svg {
                            position: relative;
                            margin-top: unset;
                            height: 28.3rem !important;
                        }
                    }

                    .item--4 {
                        z-index: 5;

                        img,
                        svg {
                            position: relative;
                            ;
                            margin-top: unset;
                            height: 39.8rem !important;
                        }
                    }

                    .item--5 {
                        z-index: 2;

                        img,
                        svg {
                            height: 20.1rem !important;
                        }
                    }

                    .item--6 {
                        z-index: 1;

                        img,
                        svg {
                            position: relative;
                            ;
                            margin-top: unset;
                            height: 37.9rem !important;
                        }
                    }
                }

                .heading__section {
                    width: clamp(28.4rem, 81.25vw + -0.85rem, 47.9rem);
                    height: auto;
                    margin-top: 5rem;
                    margin-bottom: 5rem;

                    h2 {
                        width: 100%;
                        font-size: clamp(2.4rem, 3.333vw + 1.2rem, 3.2rem);
                        text-align: center;
                    }

                    p {
                        width: 100%;
                        font-size: clamp(1.6rem, 1.667vw + 1rem, 2rem);
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .wrapper {

        .pd2__section5 {
            height: clamp(57rem, 75vw + 30rem, 75rem);
            align-items: flex-start;

            .wrapper__container {
                width: 100%;
                height: clamp(48rem, 70.833vw + 22.5rem, 65rem);
                @include display(column, center, space-between);
                overflow: hidden;
                margin-top: clamp(2rem, 12.5vw + -2.5rem, 5rem);

                .image__container {
                    width: 100%;
                    max-height: clamp(37.6rem, 72.5vw + 11.5rem, 55rem);
                    @include display(row, flex-start, flex-start);

                    img {
                        min-width: 100%;
                        object-fit: cover;
                        border-radius: 3.2rem;
                    }
                }
            }
        }
    }
}