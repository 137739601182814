@use '../util' as *;

* {
    padding: 0px;
    margin: 0px;
    border: 0px;

    &:focus {
        outline: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        tap-highlight-color: transparent;
        -webkit-user-select: none;
        user-select: none;
    }
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: auto;
    min-width: 280px;
    scroll-behavior: smooth;
}

html {
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: $font;
    -webkit-font-smoothing: antialiased;
    scrollbar-width: none;
}

body {
    line-height: 1;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::-webkit-scrollbar {
        display: none;
        /* For Chrome, Safari, and Opera */
        scrollbar-width: none;
        /* For Firefox */
        -ms-overflow-style: none;
        /* For Internet Explorer and Edge */
    }
}

.no--scroll {
    overflow: hidden;
}

button {
    cursor: pointer;
}

input,
textarea {
    outline: none;
    appearance: none;
    border: 0.2rem solid transparent;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 600;
    font-family: $font;

    &::placeholder {
        font-family: $font;
        @include text(500, 1.6rem, 2rem, null, #767A8D);
    }

    &:hover {
        background: #F3F4F7;
        border: 2px solid #989898;
    }

    &:focus {
        background: #FFFFFF;
        border: 2px solid #4861E6;
    }

    &:disabled {
        background: #F3F4F7;
        border: 2px solid #DFDFDF;
    }

    @media (max-width: 768px) {
        font-size: 1.4rem;
        font-weight: 500;
    }
}

.wrapper {
    width: 100%;
    height: auto;
    @include display(column, null, flex-start);
    padding: 0 6.4rem;
    position: relative;

    @media (max-width: 992px) {
        padding: 0 clamp(3.2rem, 14.286vw + -7.771rem, 6.4rem);
    }

    @media (max-width: 768px) {
        padding: 0 clamp(1.6rem, 3.931vw + 0.185rem, 3.2rem);
    }
}

button {
    font-family: $font;
}

.tapable-element {
    touch-action: manipulation;
    /* Prevent default browser touch interactions */
    user-select: none;
    -webkit-user-select: none;
    /* Disable text selection and highlight */
    -webkit-tap-highlight-color: transparent;
    /* For older WebKit browsers */
    tap-highlight-color: transparent;

    video {
        @extend .tapable-element;
    }
}

svg {
    user-select: none;
    pointer-events: none;
}