h1,
h2,
h3 {
    font-weight: 700;
    line-height: 1.1;
    margin-top: 0;
}

p {
    margin-top: 0;
}

a:-webkit-any-link {
    all: unset;
    display: flex;
    cursor: pointer;
}

a,
a:visited,
a:active {
    text-decoration: none;
    cursor: pointer;
    width: auto;
    height: auto;
    color: inherit;
}

ul li {
    list-style: none
}