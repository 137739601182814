@use '../util' as *;

.wrapper {

    footer {
        width: 100%;
        height: 55.4rem;
        @include display();

        .footer {
            width: 100%;
            height: 100%;
            max-width: 146rem;
            @include display(column, center, space-between);

            &--background {
                @include position(bottom, 0, left, 0);
                width: 100%;
                height: 55.4rem;
                background: #F9F9FB;
                z-index: -2;
            }

            &__top {
                width: 100%;
                height: 24.9rem;
                @include display(null, flex-start, space-between);
                margin-top: 6.9rem;

                .company__overview {
                    width: 27rem;
                    height: 100%;
                    @include display(column, flex-start, space-between);

                    a {
                        width: 100%;
                    }

                    .logo__section {
                        width: 100%;
                        height: 4.9rem;
                        @include display(null, center, space-between);

                        &--icon {
                            width: 4.9rem;
                            height: 100%;
                        }

                        &--main {
                            width: 12.2rem;
                            height: 100%;
                        }

                        &--version {
                            padding: 0.8rem 1.6rem;
                            width: 6.6rem;
                            height: 3.6rem;
                            background: rgba(72, 97, 230, 0.16);
                            border-radius: 10rem;
                            @include display();
                            @include text(600, 1.3rem, 1.6rem, null, #4861E6);
                        }
                    }

                    .contact__section {
                        width: 100%;
                        height: 16rem;
                        @include display(column, flex-start, space-between);

                        li {
                            width: 100%;
                            height: auto;
                            @include display(null, center, flex-start);

                            img {
                                width: 2.4rem;
                                height: 2.4rem;
                                margin-right: 1.7rem;
                            }

                            span {
                                @include text(400, 1.6rem, 3.2rem, -0.05rem, #767A8D);
                            }

                            &:nth-child(1) {
                                span {
                                    line-height: 2.4rem;
                                }
                            }
                        }
                    }
                }

                .company__details {
                    width: clamp(57.1rem, 23.08vw + 39.38rem, 76.3rem);
                    height: clamp(22rem, 4.028vw + 17.167rem, 24.9rem);
                    @include display(null, flex-start, flex-start);

                    .outer__list {
                        @include display(null, flex-start, space-between);
                        width: 100%;
                        height: 100%;

                        li {
                            @include display(column, flex-start, flex-start);
                            width: auto;
                            height: auto;

                            .text p {
                                @include text(600, clamp(1.6rem, 0.66vw + 0.95rem, 2rem), 3.2rem, null, #2E303A);
                                margin-bottom: 2.4rem;
                            }

                            .inner__list {
                                all: unset;
                                @include display(column, flex-start, flex-start);
                                width: auto;
                                height: auto;

                                li {
                                    all: unset;
                                    margin-bottom: 1.2rem;
                                    @include text(400, 1.6rem, 3.2rem, -0.05rem, rgba(118, 122, 141, 0.75));

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    &:hover a {
                                        color: #2E303A;
                                        transition: color 0.2s ease-in-out;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__middle {
                @include display(null, null, space-between);
                align-self: flex-end;
                width: clamp(57.1rem, 23.08vw + 39.38rem, 76.3rem);
                height: clamp(9.2rem, 1.111vw + 7.867rem, 10rem);

                .contact__section {
                    width: clamp(25.9rem, 13.82vw + 12.19rem, 34.3rem);
                    height: 100%;
                    @include display(column, flex-start, space-between);

                    p {
                        @include text(600, clamp(1.6rem, 0.66vw + 0.95rem, 2rem), 3.2rem, null, #2E303A);
                    }

                    .contact--mail {
                        width: 100%;
                        height: clamp(4.4rem, 1.97vw + 2.44rem, 5.6rem);
                        position: relative;

                        input {
                            width: 100%;
                            height: 100%;
                            padding: 1.6rem;
                            background: #EDF1F7;
                            border-radius: 1.2rem;
                            padding-right: 4.8rem;

                            &::placeholder {
                                @include text(400, 1.4rem, 1.8rem, null, #767A8D);
                            }
                        }

                        .contactDatsmeMail,
                        .contactDatsmeSend {
                            width: 2.4rem;
                            height: 2.4rem;
                            @include position(right, 1.2rem, top, 50%);
                            transform: translate(0, -50%);
                            cursor: pointer;
                            opacity: 0;
                        }

                        .contactDatsmeSend {
                            width: 3.2rem;
                            height: 3.2rem;
                        }

                        .contactDatsmeMail.active,
                        .contactDatsmeSend.active {
                            opacity: 1;
                        }
                    }
                }

                .download__section {
                    width: auto;
                    height: 100%;
                    @include display(column, flex-start, space-between);

                    p {
                        @include text(600, clamp(1.6rem, 0.66vw + 0.95rem, 2rem), 3.2rem, null, #2E303A);
                    }

                    .download--buttons {
                        width: auto;
                        height: auto;
                        @include display(null, center, space-between);

                        .googlePlay {
                            width: clamp(14.5rem, 6.41vw + 8.14rem, 18.4rem);
                            height: clamp(4.4rem, 1.97vw + 2.44rem, 5.6rem);
                            cursor: pointer;
                            margin-right: clamp(0.8rem, 1.32vw + -0.51rem, 1.6rem);
                        }

                        .appStore {
                            width: clamp(13.1rem, 5.59vw + 7.55rem, 16.5rem);
                            height: clamp(4.4rem, 1.97vw + 2.44rem, 5.6rem);
                            cursor: pointer;
                        }
                    }
                }
            }

            &__bottom {
                width: 100%;
                height: 8rem;
                @include display(null, null, space-between);
                border-top: 0.15rem solid #EEEEF1;

                p {
                    @include text(400, 1.4rem, 3.2rem, -0.05rem, rgba(118, 122, 141, 0.75));
                }

                &__list {
                    width: auto;
                    height: auto;
                    @include display(null, null, space-between);

                    .text--section {
                        display: flex;

                        ul {
                            width: auto;
                            height: auto;
                            display: flex;
                        }

                        li {
                            width: auto;
                            height: auto;
                            @include text(400, 1.4rem, normal, -0.007rem, rgba(118, 122, 141, 0.75));

                            &:first-child {
                                margin-right: 2.4rem;
                            }

                            &:hover a {
                                color: #2E303A;
                                transition: color 0.2s ease-in-out;
                            }
                        }
                    }

                    .sepration {
                        margin: 0 2.4rem;
                        width: 0.15rem;
                        height: 2.4rem;
                        background: #EEEEF1;
                    }

                    .icon--section {
                        width: auto;
                        height: auto;
                        display: flex;

                        ul {
                            width: auto;
                            height: auto;
                            display: flex;
                        }

                        li {
                            width: auto;
                            height: auto;

                            img {
                                width: 2.4rem;
                                height: 2.4rem;
                                margin-right: 1.6rem;
                                filter: brightness(1);
                                transition: filter 0.2s ease-in-out;
                            }

                            &:hover {
                                filter: brightness(0);
                                transition: filter 0.2s ease-in-out;
                            }

                            &:last-child {
                                img {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .wrapper {
        footer {
            height: clamp(49.6rem, 24.038vw + 25.754rem, 54.6rem);

            .footer {

                &--background {
                    height: clamp(49.6rem, 24.038vw + 25.754rem, 54.6rem);
                }

                &__top {
                    height: clamp(19.6rem, 25.481vw + -5.677rem, 24.9rem);

                    .company__overview {
                        width: clamp(22.2rem, 28.846vw + -6.415rem, 28.2rem);

                        .logo__section {
                            height: clamp(4rem, 4.327vw + -0.292rem, 4.9rem);

                            &--icon {
                                width: clamp(4rem, 4.327vw + -0.292rem, 4.9rem);
                            }

                            &--main {
                                width: clamp(10rem, 10.577vw + -0.492rem, 12.2rem);
                            }

                            &--version {
                                width: clamp(5.3rem, 6.25vw + -0.9rem, 6.6rem);
                                height: clamp(2.9rem, 3.365vw + -0.438rem, 3.6rem);
                            }
                        }

                        .contact__section {
                            height: clamp(11.6rem, 21.154vw + -9.385rem, 16rem);

                            li {
                                span {
                                    font-size: clamp(1.4rem, 0.962vw + 0.446rem, 1.6rem);
                                    line-height: clamp(1.8rem, 6.731vw + -4.877rem, 3.2rem);
                                }

                                &:nth-child(1) {
                                    span {
                                        line-height: clamp(1.8rem, 2.885vw + -1.062rem, 2.4rem);
                                    }
                                }
                            }
                        }
                    }

                    .company__details {
                        width: clamp(57.1rem, 40.865vw + 16.562rem, 65.6rem);
                        height: clamp(16.4rem, 26.923vw + -10.308rem, 22rem);

                        .outer__list {
                            li {
                                .text {
                                    font-size: clamp(1.6rem, 0.962vw + 0.646rem, 1.8rem);
                                }

                                .inner__list {
                                    li {
                                        font-size: clamp(1.4rem, 0.962vw + 0.446rem, 1.6rem);
                                        line-height: clamp(1.8rem, 6.731vw + -4.877rem, 3.2rem);
                                    }
                                }
                            }
                        }
                    }
                }

                &__middle {
                    width: clamp(57.1rem, 40.865vw + 16.562rem, 65.6rem);
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .wrapper {
        footer {
            height: 60.7rem;

            .footer {

                &--background {
                    height: 60.7rem;
                }

                &__top {
                    @include display(column, flex-start, space-between);
                    height: 30.8rem;
                    margin-top: 4.8rem;

                    .company__overview {
                        width: 100%;
                        height: 8.8rem;

                        .logo__section {
                            max-width: 21.9rem;
                        }

                        .contact__section {
                            height: 3.2rem;
                            @include display(row, center, space-between);

                            li {
                                width: auto;

                                img {
                                    margin-right: 1.2rem;
                                }
                            }
                        }
                    }

                    .company__details {
                        width: 100%;
                        height: 18.8rem;
                        padding-top: 2.4rem;
                        border-top: 0.1rem solid #eeeef1;
                    }
                }

                &__middle {
                    width: 100%;
                    height: 9.2rem;

                    .contact__section {
                        width: 30rem;
                    }
                }

                &__bottom {
                    height: 10.4rem;
                    padding: 1.6rem 0;
                    align-items: flex-start;

                    &__list {
                        @include display(column, null, space-between);

                        .text--section {
                            li {
                                @include text(400, 1.4rem, 3.2rem, -0.05rem, rgba(118, 122, 141, 0.75));

                                &:first-child {
                                    margin-right: 4rem;
                                }
                            }

                            margin-bottom: 1.6rem;
                        }

                        .sepration {
                            display: none;
                        }

                        .icon--section {

                            li {
                                img {
                                    margin-right: 2.4rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        footer {
            height: auto;

            .footer {
                align-items: flex-start;

                &--background {
                    height: 127.6rem;
                }

                &__top {
                    @include display(column, flex-start, space-between);
                    height: auto;
                    max-width: 25.1rem;

                    .company__overview {
                        width: 100%;
                        height: 21.6rem;
                        margin-bottom: 3.2rem;

                        .logo__section {
                            max-width: 21.9rem;
                        }

                        .contact__section {
                            height: 15.2rem;
                            @include display(column, flex-start, space-between);

                            li {
                                width: auto;

                                img {
                                    margin-right: 1.2rem;
                                }
                            }
                        }
                    }

                    .company__details {
                        width: 100%;
                        height: auto;
                        padding-top: 1.6rem;
                        border-top: 0.1rem solid #eeeef1;

                        .outer__list {
                            flex-direction: column;

                            li {
                                margin-bottom: 2.4rem;

                                .text {
                                    margin-bottom: 1.2rem;

                                    p {
                                        margin-bottom: 0;
                                    }
                                }

                                &:last-child {
                                    margin-bottom: 2.8rem;
                                }
                            }
                        }
                    }
                }

                &__middle {
                    width: 100%;
                    max-width: 32.8rem;
                    height: auto;
                    flex-direction: column;
                    align-items: flex-start;
                    align-self: flex-start;
                    margin-bottom: 2.8rem;

                    .contact__section {
                        width: 32.8rem;
                        max-width: 100%;
                        height: 9.2rem;
                        margin-bottom: 2.4rem;
                    }

                    .download__section {
                        width: 32.8rem;
                        max-width: 100%;
                        height: 9.4rem;

                        .download--buttons {
                            height: 5rem;

                            .googlePlay {
                                width: 16.7rem;
                                height: 100%;
                                margin-right: 1rem;
                            }

                            .appStore {
                                width: 15.1rem;
                                height: 100%;
                            }
                        }
                    }
                }

                &__bottom {
                    width: 32.8rem;
                    height: 10.8rem;
                    padding: 1.2rem 0;
                    @include display(column-reverse, center, space-between);

                    p {
                        max-width: 24.6rem;
                        line-height: normal;
                        font-size: 1.2rem;
                        text-align: center
                    }

                    &__list {
                        @include display(row-reverse, null, space-between);

                        .text--section {
                            li {
                                @include text(400, 1.4rem, 3.2rem, -0.05rem, rgba(118, 122, 141, 0.75));

                                &:first-child {
                                    margin-right: 1.2rem;
                                }
                            }

                            margin-bottom: 0;
                        }

                        .icon--section {
                            margin-right: 4.4rem;

                            li {
                                img {
                                    margin-right: 1.6rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}