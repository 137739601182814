@use '../../util' as *;

.wrapper {
    .team {
        @include display(column);
        width: 100%;
        height: auto;
        max-width: 146rem;
        margin: 0 6.4rem;

        &__header {
            width: 100%;
            height: clamp(21rem, 2.371vw + 18.648rem, 23.2rem);
            max-width: 70.6rem;
            margin: clamp(14.2rem, 48.214vw + -22.829rem, 25rem) 0 clamp(7.2rem, 8.036vw + 1.029rem, 9rem) 0;
            @include display(column, null, space-between);

            h1 {
                text-align: center;
                @include text(700, clamp(6.4rem, 0.862vw + 5.545rem, 7.2rem), clamp(7.7rem, 1.185vw + 6.524rem, 8.8rem), -0.1rem, #2E303A);

                span {
                    color: #4861E6;
                }
            }

            p {
                text-align: center;
                @include text(400, 2rem, 3.2rem, -0.05rem, #767A8D);
            }
        }

        &__member {
            margin-bottom: 10rem;
            width: auto;
            height: auto;
            display: grid;
            place-items: center;
            grid-gap: 2.9rem;
            grid-template-columns: repeat(9, 1fr);
            grid-template-rows: repeat(4, 1fr);
            grid-template-areas:
                "a a a . . . b b b"
                "a a a c c c b b b"
                "e e e c c c d d d"
                "e e e . . . d d d";

            .teamgrid {
                width: clamp(26.9rem, 8.082vw + 18.883rem, 34.4rem);
                height: clamp(26.9rem, 8.082vw + 18.883rem, 34.4rem);
                @include display(null, flex-end, center);
                background-size: cover;
                background-repeat: no-repeat;
                overflow: hidden;
                box-shadow: 0px 3rem 3rem -1.5rem rgba(0, 0, 0, 0.5);

                .content {
                    width: 100%;
                    height: auto;
                    padding: clamp(1.3rem, 0.655vw + 0.65rem, 1.6rem) clamp(1.9rem, 1.092vw + 0.817rem, 2.4rem);
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

                    h2 {
                        @include text(700, clamp(2rem, 1.747vw + 0.267rem, 2.8rem), clamp(3rem, 2.62vw + 0.401rem, 4.2rem), null, #FFF);
                    }

                    p {
                        @include text(400, clamp(1.2rem, 0.893vw + 0.514rem, 1.4rem), clamp(1.8rem, 1.339vw + 0.771rem, 2.1rem), null, #FFF);
                        opacity: 0.75;
                    }
                }
            }

            .curve-left {
                border-radius: clamp(6.25rem, 3.821vw + 2.46rem, 8rem) clamp(6.25rem, 3.821vw + 2.46rem, 8rem) 0 clamp(6.25rem, 3.821vw + 2.46rem, 8rem);
                text-align: right;
            }

            .curve-right {
                border-radius: clamp(6.25rem, 3.821vw + 2.46rem, 8rem) clamp(6.25rem, 3.821vw + 2.46rem, 8rem) clamp(6.25rem, 3.821vw + 2.46rem, 8rem) 0;
                text-align: left;
            }

            &--member1 {
                background: url(/public/assets/team-sushant.webp);
                grid-area: a;
            }

            &--member2 {
                background: url(/public/assets/team-aditya.webp);
                grid-area: b;
            }

            &--member3 {
                background: url(/public/assets/team-shasta.webp);
                grid-area: c;
            }

            &--member4 {
                background: url(/public/assets/team-prateek.jpg);
                grid-area: d;
            }

            &--member5 {
                background: url(/public/assets/team-siddhant.webp);
                grid-area: e;
            }

            // &--member6 {
            //     background: url(/public/assets/team-pulkit.png);
            //     grid-area: f;
            // }

            // &--member7 {
            //     background: url(/public/assets/team-jatin.png);
            //     grid-area: g;
            //     margin-top: -5rem;
            // }

            // &--member8 {
            //     background: url(/public/assets/team-saurav.png);
            //     grid-area: h;
            //     margin-top: -5rem;
            // }
        }
    }
}

@media (max-width: 1450px) {
    .wrapper {
        .team {
            &__member {
                height: auto;
                grid-gap: 2rem;
                grid-template-columns: repeat(9, 1fr);
                grid-template-rows: repeat(6, 1fr);
                grid-template-areas:
                    "a a a b b b c c c"
                    "a a a b b b c c c"
                    "a a a b b b c c c"
                    ". d d d . e e e ."
                    ". d d d . e e e ."
                    ". d d d . e e e .";

                &--member3,
                &--member4,
                &--member7,
                &--member8 {
                    margin-top: 0;
                }

                &--member4 {
                    margin-right: -10rem;
                }

                &--member5 {
                    margin-left: -10rem;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .wrapper {
        .team {
            &__header {
                width: clamp(61.7rem, 39.732vw + 31.186rem, 70.6rem);
                height: clamp(18.3rem, 12.054vw + 9.043rem, 21rem);

                h1 {
                    font-size: clamp(5.6rem, 3.571vw + 2.857rem, 6.4rem);
                    line-height: clamp(6.7rem, 4.464vw + 3.271rem, 7.7rem);
                }

                p {
                    font-size: clamp(1.8rem, 0.893vw + 1.114rem, 2rem);
                }
            }

            &__member {

                .teamgrid {
                    width: clamp(21.9rem, 22.321vw + 4.757rem, 26.9rem);
                    height: clamp(21.9rem, 22.321vw + 4.757rem, 26.9rem);

                    .content {
                        padding: clamp(1rem, 1.339vw + -0.029rem, 1.3rem) clamp(1.5rem, 1.786vw + 0.129rem, 1.9rem);

                        h2 {
                            font-size: clamp(1.6rem, 1.786vw + 0.229rem, 2rem);
                            line-height: clamp(2.4rem, 2.679vw + 0.343rem, 3rem);
                        }
                    }
                }

                .curve-left {
                    border-radius: clamp(5.1rem, 5.134vw + 1.157rem, 6.25rem) clamp(5.1rem, 5.134vw + 1.157rem, 6.25rem) 0 clamp(5.1rem, 5.134vw + 1.157rem, 6.25rem);
                }

                .curve-right {
                    border-radius: clamp(5.1rem, 5.134vw + 1.157rem, 6.25rem) clamp(5.1rem, 5.134vw + 1.157rem, 6.25rem) clamp(5.1rem, 5.134vw + 1.157rem, 6.25rem) 0;
                }

                &--member4 {
                    margin-right: clamp(-9.5rem, calc(-6.696vw + -2.857rem), -8rem);
                }
    
                &--member5 {
                    margin-left: clamp(-9.5rem, calc(-6.696vw + -2.857rem), -8rem);
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        .team {
            &__header {
                width: clamp(34.4rem, 66.912vw + 10.312rem, 61.7rem);
                height: clamp(11.7rem, 16.176vw + 5.876rem, 18.3rem);
                margin: clamp(12.4rem, 4.412vw + 10.812rem, 14.2rem) 0 clamp(4.8rem, 5.882vw + 2.682rem, 7.2rem) 0;

                h1 {
                    font-size: clamp(3.2rem, 5.882vw + 1.082rem, 5.6rem);
                    line-height: clamp(3.8rem, 7.108vw + 1.241rem, 6.7rem);
                }

                p {
                    font-size: clamp(1.6rem, 0.49vw + 1.424rem, 1.8rem);
                }
            }

            &__member {
                max-width: 45rem;
                grid-gap: 1.6rem;
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: repeat(9, 1fr);
                grid-template-areas:
                    "a a a b b b"
                    "a a a b b b"
                    "a a a b b b"
                    "c c c d d d"
                    "c c c d d d"
                    "c c c d d d"
                    "e e e . . ."
                    "e e e . . ."
                    "e e e . . .";

                .teamgrid {
                    width: clamp(20.3rem, 5.556vw + 17.633rem, 21.9rem);
                    height: clamp(20.3rem, 5.556vw + 17.633rem, 21.9rem);
                    box-shadow: 0px 3rem 3rem -1.5rem rgba(0, 0, 0, 0.5);

                    .content {
                        padding: clamp(0.7rem, 0.735vw + 0.435rem, 1rem) clamp(1rem, 1.225vw + 0.559rem, 1.5rem);

                        h2 {
                            font-size: clamp(1.07rem, 1.299vw + 0.602rem, 1.6rem);
                            line-height: clamp(1.6rem, 1.961vw + 0.894rem, 2.4rem);
                        }

                        p {
                            font-size: clamp(0.8rem, 0.98vw + 0.447rem, 1.2rem);
                            line-height: clamp(1.2rem, 1.471vw + 0.671rem, 1.8rem);
                        }
                    }
                }

                .curve-left {
                    border-radius: clamp(4.5rem, 2.083vw + 3.5rem, 5.1rem) clamp(4.5rem, 2.083vw + 3.5rem, 5.1rem) 0 clamp(4.5rem, 2.083vw + 3.5rem, 5.1rem);
                }

                .curve-right {
                    border-radius: clamp(4.5rem, 2.083vw + 3.5rem, 5.1rem) clamp(4.5rem, 2.083vw + 3.5rem, 5.1rem) clamp(4.5rem, 2.083vw + 3.5rem, 5.1rem) 0;
                }

                &--member4 {
                    margin-right: 0;
                }
    
                &--member5 {
                    margin-left: 0;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .wrapper {
        .team {

            &__header {
                width: 90%;
                height: 19.9rem;
                margin: 9.2rem 0 6.4rem 0;

                h1 {
                    font-size: 4.4rem;
                    line-height: 5.3rem;
                    text-align: left;
                }

                p {
                    width: 100%;
                    line-height: 3.2rem;
                    text-align: left;
                }
            }

            &__member {
                width: 100%;
                max-width: 45rem;
                grid-gap: 5.6rem;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(10, 1fr);
                grid-template-areas:
                    "a a"
                    "a a"
                    "b b"
                    "b b"
                    "c c"
                    "c c"
                    "d d"
                    "d d"
                    "e e"
                    "e e";

                .teamgrid {
                    width: 27.2rem;
                    height: 27.2rem;
                    box-shadow: 0px 3.5rem 4rem -1rem rgba(0, 0, 0, 0.5);

                    .content {
                        padding: 1.3rem 1.9rem;

                        h2 {
                            font-size: 2rem;
                            line-height: 3rem;
                        }

                        p {
                            font-size: 1.5rem;
                            line-height: 2.2rem;
                        }
                    }
                }

                .curve-left {
                    border-radius: 6.4rem 6.4rem 0 6.4rem;
                }

                .curve-right {
                    border-radius: 6.4rem 6.4rem 6.4rem 0;
                }
            }
        }
    }
}